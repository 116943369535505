import React from "react"
import { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import { useHistory } from "react-router-dom";

import { getPermisosUsuarios, getTipoPermisos, activarDesactivarPermisoUsuario, GetPermisoUsuarioActual } from "../../helpers/empresa";
import FullScreenDialog from "../fullScreenDialog";
import CustomTable, { StyledTableCell, StyledTableRow } from "../customTable";
import NuevoPermisoUsuario from "./nuevoUsuarioEmpresa";
import CustomDialog from "../customDialog";
import SpeedDialButton from "../sdButton";

import { useAuth0 } from "../../react-auth0-spa";

//ESTA VISTA PERMITE VISUALIZAR LOS USUARIOS DE LA EMPRESA CON SUS PERMISOS
//CREAR USUARIOS NUEVOS

export default function UsuariosEmpresa() {
    const { user, getTokenSilently } = useAuth0();
    const navegar = useHistory();
    const [openDialog, setOpenDialog] = React.useState(false);
    const initialCustomDialog = { show: false, title: "", text: "", buttons: [] };
    const [customDialog, setCustomDialog] = React.useState(initialCustomDialog);
    const [permisosUsuarios, setPermisosUsuarios] = useState([]);
    const tipoPermisos = useRef([]);
    const [usuarioTareas, setUsuarioTareas] = useState(false);

    const initialState = {
        permisoUsuarioID: 0,
        email: '',
        password: '',
        usuarioID: '',
        permisoID: 1,
    }

    const [usuarioSeleccionado, setUsuarioSeleccionado] = useState(initialState)

    const handleClose = () => {
        setOpenDialog(false)
        setUsuarioSeleccionado(initialState)
    };

    useEffect(() => {
        BuscarTipoPermisos();

        BuscarPermisosUsuarios();

        BuscarPermisoUsuarioActual();
    }, [])

    const BuscarTipoPermisos = async () => {
        const token = await getTokenSilently();
        getTipoPermisos(token)
            .then(respuestaTipoPermisos => {
                tipoPermisos.current = [...respuestaTipoPermisos];
            
            })
    }
    const BuscarPermisosUsuarios = async () => {
        const token = await getTokenSilently();
        getPermisosUsuarios(user, token)
            .then(respuestaPermisosUsuarios => {
                setPermisosUsuarios(respuestaPermisosUsuarios);
            })
    }
    const BuscarPermisoUsuarioActual = async () => {
        const token = await getTokenSilently();
        GetPermisoUsuarioActual(user, token)
            .then(respuestaPermiso => {
                setUsuarioTareas(respuestaPermiso.permisoID === 3);
            })
    }


    const desvincularPermisoUsuario = permisoUsuario => {
        setCustomDialog({
            show: true, 
            title: !permisoUsuario.desvinculado ? "Desvincular Usuario" : "Vincular Usuario", 
            text: !permisoUsuario.desvinculado ? "¿Está seguro que desea desvincular este Usuario del Sistema?" : "¿Está seguro que desea vincular este Usuario del Sistema?", 
            buttons: [
                <Button onClick={() => setCustomDialog(initialCustomDialog)}>Cancelar</Button>,
                <Button onClick={() => desvincular(permisoUsuario)}>Aceptar</Button>,
            ]
        });
    };

    const desvincular = async (permisoUsuario) => {
        const token = await getTokenSilently();
        const result = await activarDesactivarPermisoUsuario(user, token, permisoUsuario);
        if (result) {
            guardarPermisoUsuario({...permisoUsuario, desvinculado : result.desvinculado}, "DELETE")
        }
    }

    const editarPermisoUsuario = permisoUsuario => {
        setUsuarioSeleccionado(permisoUsuario);
        setOpenDialog(true);
    };

    const guardarPermisoUsuario = (permisoUsuario, action) => {
        switch (action) {
            case "POST":
                if(permisoUsuario.permisoID > 0){
                    setPermisosUsuarios([...permisosUsuarios, permisoUsuario]); 
                }
                else{
                    setCustomDialog(initialCustomDialog)
                }
                break;
            case "PUT":
                setPermisosUsuarios([...permisosUsuarios.map(tt => tt.permisoUsuarioID === permisoUsuario.permisoUsuarioID ? {...permisoUsuario} : tt)]); 
                break;
            case "DELETE":
                setPermisosUsuarios([...permisosUsuarios.map(tt => tt.permisoUsuarioID === permisoUsuario.permisoUsuarioID ? {...permisoUsuario} : tt)]); 
                setCustomDialog(initialCustomDialog)
                break;
            default:
                console.log('guardarPermisoUsuario()', { permisoUsuario, action })
        }
        handleClose();
    }

    return (
        <div>
            <h1 className="titulosVistas">Usuarios Empresa</h1>
            <hr />
            <CustomTable headers={["Nombre", "Email", "Permiso", ""]} rows={permisosUsuarios}>
                {permisosUsuarios.map((permisoUsuario) => (
                    <StyledTableRow className={permisoUsuario.desvinculado && "fondo-rojo-tabla"} sx={{position: "relative"}} key={permisoUsuario.permisoUsuarioID}>
                        <StyledTableCell sx={{zIndex:5}}>
                        {!permisoUsuario.desvinculado && <button className="buttonActionTable" onClick={() => editarPermisoUsuario(permisoUsuario)} disabled={usuarioTareas}></button>}{permisoUsuario.nombreUsuario}
                        </StyledTableCell>
                        <StyledTableCell sx={{zIndex:5}}>
                        {!permisoUsuario.desvinculado && <button className="buttonActionTable" onClick={() => editarPermisoUsuario(permisoUsuario)} disabled={usuarioTareas}></button>}{permisoUsuario.email}
                        </StyledTableCell>
                        <StyledTableCell sx={{zIndex:5}}>
                             {permisoUsuario.permisoNombre}
                        </StyledTableCell>
                        <StyledTableCell sx={{width:"1px", zIndex:10}}>
                        
                            <IconButton onClick={() => desvincularPermisoUsuario(permisoUsuario)} aria-label="delete" disabled={usuarioTareas}>
                            {!permisoUsuario.desvinculado ?   <DeleteIcon /> : <CheckIcon />}
                               
                            </IconButton>
                           
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
            </CustomTable>

            <SpeedDialButton disabled={usuarioTareas} options={[["Add","Agregar Usuario", () => setOpenDialog(true)], ["Edit", "Editar Empresa", () => navegar.push("/empresa/datosEmpresa")]]} />

            <FullScreenDialog
                open={openDialog}
                handleClose={handleClose}
                titulo="Datos del Usuario"
            >
                <NuevoPermisoUsuario usuarioSeleccionado={usuarioSeleccionado} guardarPermisoUsuario={guardarPermisoUsuario} tipoPermisos={tipoPermisos.current} />
            </FullScreenDialog>

            <CustomDialog {...customDialog} />
        </div>
    )
}