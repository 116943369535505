export const getLocalidades = async(user, token) => {
  const response = await fetch('api/Localidades/' + user.sub, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
  });

  const data = await response.json();
  return data;
}

export const postLocalidad = async(localidad, user, token) => {
  localidad.nombre = localidad.nombre.toUpperCase();
  
  const response = await fetch('api/Localidades/' + user.sub, {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
      body: JSON.stringify(localidad)
  });

  const data = await response.json();
  return data;
}

export const putLocalidad = async(localidad, user, token) => {
  localidad.nombre = localidad.nombre.toUpperCase();

  const response = await fetch('api/Localidades/' + localidad.localidadID + '/' + user.sub, {
      method: "PUT",
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
      body: JSON.stringify(localidad)
  });

  const data = await response.json();
  return data;
}

export const deleteLocalidad = async(localidad, user, token) => {
  const response = await fetch('api/Localidades/' + localidad.localidadID + '/' + user.sub, {
      method: "DELETE",
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
  });

  const data = await response.json();
  return data;
}
