import React, { useState, Fragment, useRef } from "react"
import moment from "moment";
import { isEqual } from 'lodash';
import { Autocomplete, Typography, MenuItem, Chip, TextField, Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
//Iconos
import CheckIcon from '@mui/icons-material/Check';
//Helpers
import { postTarea, putTarea } from "../../helpers/tareas";

//Custom Components
import CustomAutocomplete from "../customAutocomplete";
import FloatingActionButtons from "../fabButton";
import Tabs from "../customTabs";


import { useAuth0 } from "../../react-auth0-spa";


export default function NuevaTarea({ tareaSeleccionada, estadosTareas, disabled, guardar, servicios, tiposTareas, usuariosEmpresa }) {
    const { user, getTokenSilently } = useAuth0();
    const [ tarea, setTarea ] = useState(tareaSeleccionada);

    const tareaSinCambios = useRef(tareaSeleccionada);

    const theme = useTheme();
    const matches_sm = useMediaQuery(theme.breakpoints.up('sm'));

    const handleChange = (evento) => {
        const { name, value } = evento.target;
        setTarea({ ...tarea, [ name ]: value });
    }

    const handleChangeServicio = (_, servicio) => {
        if (servicio === null) {
            setTarea({ ...tarea, servicioTitulo: "", servicioID: 0 });
        }
        else {
            const { label, value } = servicio;
            setTarea({ ...tarea, servicioTitulo: label, servicioID: value });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const token = await getTokenSilently();
        if (tarea.tareaID > 0) {
            const result = await putTarea(tarea, user, token);
            guardar(result, "PUT")
        }
        else {
            const result = await postTarea(tarea, user, token);
            guardar(result, "POST")
        }
    }

    const administrarUsuarios = (e, usuariosTarea) => {
        setTarea({ ...tarea, listadoUsuarios: [...usuariosTarea] });
    }

    const handleBlur = (e) => {
        const { name } = e.target;
        if(name === 'fechaTarea') {
            if(moment(tarea.fechaTarea).isAfter(tarea.fechaTareaFin)){
                setTarea({ ...tarea, fechaTareaFin: tarea.fechaTarea });
            }
        }

        if(name === 'fechaTareaFin') {
            if(moment(tarea.fechaTareaFin).isBefore(tarea.fechaTarea)){
                setTarea({ ...tarea, fechaTarea: tarea.fechaTareaFin });
            }
        }
    }

    const EstadoTareaComponent = () => {
        const estadoTarea = estadosTareas.find(estado => estado.estadoTareaID === tarea.estadoTareaID);
        const { label, icon, color } = estadoTarea;
        return <Chip {...{label, icon, color}} sx={{ height: 'auto' }} />
    }

    const FechasCambiosEstadoComponent = () => {
        const fechasMostrar = [tarea.fechaCreacion, tarea.fechaInicio, tarea.fechaFinalizacion].slice(0, tarea.estadoTareaID);
        const usuariosMostrar = [tarea.usuarioCreacionEmail, tarea.usuarioInicioEmail, tarea.usuarioFinalizacionEmail].slice(0, tarea.estadoTareaID);
        return (
            <Fragment>
                {fechasMostrar.map((fecha, index, arr) => (
                    <Box 
                        key={fecha + index}
                        sx={(theme) => ({ 
                            display: 'inline-flex', 
                            flexDirection: 'column',
                            borderColor: theme.palette.text.secondary,
                            color: index === arr.length - 1 ? theme.palette.text.primary : theme.palette.text.secondary,
                            pl: 1 
                        
                        })}
                    >
                        <Typography variant="caption" sx={{ fontSize: '10px' }}>
                            {index === 0 ? 'Creada' : index === 1 ? 'Comenzada' : 'Finalizada'}
                        </Typography>
                        <Typography variant="caption" sx={{ fontSize: '10px' }}>
                            {moment(fecha).format('lll')}
                        </Typography>
                        <Typography variant="caption" sx={{ fontSize: '10px' }}>
                            {usuariosMostrar[index]}
                        </Typography>
                    </Box>
                ))}
            </Fragment>
        )
    }

    const BotonGuardarComponent = () => {
        if(tarea.estadoTareaID === 3 || isEqual(tarea, tareaSinCambios.current)) {
            return null
        }

        return (
            <FloatingActionButtons type="submit" color="success" bottom={tarea.tareaID !== 0 ? 60 : null}>
                <CheckIcon />
            </FloatingActionButtons>
        )
    }


    return (
        <Box
            component="form"
            sx={{ display: 'flex', flexWrap: 'wrap' }}
            autoComplete="off"
            onSubmit={handleSubmit}
        >
            {tarea.tareaID !== 0 && (
                <Box as="div" sx={{ display: 'flex', flexDirection: matches_sm ? 'row' : 'column', justifyContent: 'start', gap: 1, width: '100%' }}>
                    <EstadoTareaComponent />
                    <FechasCambiosEstadoComponent />
                </Box> 
            )}

            <TextField
                required
                autoFocus
                name="nombre"
                label="Nombre"
                value={tarea.nombre}
                variant="standard"
                fullWidth
                margin="normal"
                onChange={handleChange}
                color="info"
                inputProps={{ style: { textTransform: "uppercase" } }}
                disabled={disabled || tarea.estadoTareaID === 3}
            />

            <TextField
                name="fechaTarea"
                required
                label="Fecha Desde"
                type="datetime-local"
                value={moment(tarea.fechaTarea).format("yyyy-MM-DDTHH:mm")}
                variant="standard"
                fullWidth
                margin="normal"
                onChange={handleChange}
                onBlur={handleBlur}
                color="info"
                disabled={disabled || tarea.estadoTareaID === 3}
            />
            <TextField
                name="fechaTareaFin"
                required
                label="Fecha Hasta"
                type="datetime-local"
                value={moment(tarea.fechaTareaFin).format("yyyy-MM-DDTHH:mm")}
                variant="standard"
                fullWidth
                margin="normal"
                onChange={handleChange}
                onBlur={handleBlur}
                color="info"
                disabled={disabled || tarea.estadoTareaID === 3}
            />

            <Autocomplete
                disablePortal
                name="servicioID"
                id="servicioID"
                fullWidth
                onChange={handleChangeServicio}
                disabled={disabled || tarea.estadoTareaID === 3}
                value={tarea.servicioID === 0 ? "" : { label: tarea.servicioTitulo, value: tarea.servicioID }}
                options={servicios.map((option) => ({label: option.titulo, value: option.servicioID}))}
                renderInput={params => (
                    <TextField {...params}
                        label="Servicio Relacionado"
                        color="info"
                        disabled={disabled || tarea.estadoTareaID === 3}
                        variant="standard"
                        margin="normal"
                    />
                )}
            />

            <TextField
                name="tipoTareaID"
                required
                id="tipoTareaID"
                select
                label="Tipo de Tarea"
                defaultValue=""
                value={tarea.tipoTareaID === 0 ? "" : tarea.tipoTareaID}
                variant="standard"
                fullWidth
                margin="normal"
                onChange={handleChange}
                color="info"
                disabled={disabled || tarea.estadoTareaID === 3}
            >
                {tiposTareas.map((option) => (
                    <MenuItem key={option.tipoTareaID} value={option.tipoTareaID}>
                        {option.tipoTareaNombre}
                    </MenuItem>
                ))}
            </TextField>

            <Tabs 
                tabs={["Detalles", "Observaciones"]} 
                content={[
                    <TextField
                        placeholder="Detalles de la tarea..."
                        name="detalle"
                        defaultValue={tarea.detalle}
                        variant="standard"
                        multiline
                        rows={3}
                        fullWidth
                        margin="normal"
                        onChange={handleChange}
                        color="info"
                        disabled={disabled || tarea.estadoTareaID === 3}
                    />,
                    <TextField
                        placeholder="Observaciones de la tarea..."
                        name="observacionInterna"
                        defaultValue={tarea.observacionInterna}
                        variant="standard"
                        multiline
                        rows={3}
                        fullWidth
                        margin="normal"
                        onChange={handleChange}
                        color="info"
                        disabled={tarea.estadoTareaID === 3}
                    />
                ]} 
            />

            <CustomAutocomplete 
                elements={tarea.listadoUsuarios ? usuariosEmpresa.filter(elem => !tarea.listadoUsuarios.some(s => s.usuarioID === elem.usuarioID)) : []} 
                onChange={administrarUsuarios} 
                elementsActive={tarea.listadoUsuarios} 
                disabled={disabled || tarea.estadoTareaID === 3}
                fullWidth
                sx={{ marginTop: '10px' }}
            />

            <BotonGuardarComponent />
        </Box>
    )
}