import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

//Icons
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import TableViewIcon from '@mui/icons-material/TableView';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import ActivarNotificacion from '../activarNotificacion'

const Container = styled(Box)(({theme}) => ({
    display: 'flex', 
    flexDirection: 'column', 
    gap: 20, 
    flexWrap: 'wrap',
    ['& > .MuiBox-root:not(:last-of-type)']: {
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.mode === 'dark' ? '#383838' : '#dddddd',
        paddingBottom: 20
    }
}))

export default function Configuracion() {
    const [vistaTareas, setVistaTareas] = useState('')
    const [filtros, setFiltros] = useState('')
    const [filtrosObservacion, setFiltrosObservacion] = useState('')
    
    useEffect(() => {
        const vistaTareaConfig = window.localStorage.getItem('vista-tareas') || 'panel';
        if(vistaTareaConfig) {
            setVistaTareas(vistaTareaConfig)
        }
    }, []);

    const handleVistaTarea = (vista) => {
        setVistaTareas(vista);
        window.localStorage.setItem('vista-tareas', vista)
    }
    useEffect(() => {
        const filtrosConfig = window.localStorage.getItem('filtroMensualStart') || "week";
        if(filtrosConfig) {
            setFiltros(filtrosConfig)
        }
    }, []);

    useEffect(() => {
        const filtrosConfi = window.localStorage.getItem('filtroMensualEdn') || "week";
        if(filtrosConfi) {
            setFiltros(filtrosConfi)
        }
    }, []);

    const handleFiltros = (filtro) => {
        setFiltros(filtro);
        window.localStorage.setItem('filtroMensualStart', filtro);
        window.localStorage.setItem('filtroMensualEdn', filtro);
    }

    useEffect(() => {
        const filtrosConfig = window.localStorage.getItem('filtroMensualStartObservacion') || "week";
        if(filtrosConfig) {
            setFiltrosObservacion(filtrosConfig)
        }
    }, []);

    useEffect(() => {
        const filtrosConfi = window.localStorage.getItem('filtroMensualEdnObservacion') || "week";
        if(filtrosConfi) {
            setFiltrosObservacion(filtrosConfi)
        }
    }, []);

    const handleFiltrosObservacion = (filtro) => {
        setFiltrosObservacion(filtro);
        window.localStorage.setItem('filtroMensualStartObservacion', filtro);
        window.localStorage.setItem('filtroMensualEdnObservacion', filtro);
    }
    

    return (
        <div>
            <h1 className="titulosVistas">Configuraci&oacute;n</h1>
            <hr />

            <Container>
                <Box component="section">
                    <Typography variant='body1'>Notificaciones</Typography>
                    <Box component="div" sx={{ my: 1 }}>
                        <ActivarNotificacion />
                    </Box> 
                </Box>
                {/* <Box component="section">
                    <Typography variant='body1'>Vista de Tareas</Typography>
                    <Box component="div" sx={{ my: 1 }}>
                        <Button 
                            size="small" 
                            variant={vistaTareas === 'table' ? "contained" : "outlined"} 
                            startIcon={<TableViewIcon />}
                            onClick={() => handleVistaTarea('table')}
                        >
                            Tabla
                        </Button>
                        <Button 
                            sx={{ ml: 1 }}
                            size="small" 
                            variant={vistaTareas === 'panel' ? "contained" : "outlined"} 
                            startIcon={<CalendarViewMonthIcon />}
                            onClick={() => handleVistaTarea('panel')}
                        >
                            Panel
                        </Button>
                    </Box> 
                    
                </Box> */}
                {/* {vistaTareas === 'table' && ( */}
                <Box component="section">
                    <Typography variant='body1'>Filtrar tareas por mes</Typography>
                    <Box component="div" sx={{ my: 1 }}>
                        <Button 
                            
                            size="small" 
                            variant={filtros === "week" ? "contained" : "outlined"} 
                            startIcon={<ViewWeekIcon />}
                            onClick={() => handleFiltros("week")}
                        >
                            Semana
                        </Button>
                        <Button 
                        sx={{ ml: 1 }}
                            size="small" 
                            variant={filtros === "month" ? "contained" : "outlined"} 
                            startIcon={<CalendarMonthIcon />}
                            onClick={() => handleFiltros("month")}
                        >
                            Mes
                        </Button>
                    </Box> 
                    
                </Box>
                {/* )} */}
                <Box component="section">
                    <Typography variant='body1'>Filtrar observaciones por mes </Typography>
                    <Box component="div" sx={{ my: 1 }}>
                        <Button 
                            
                            size="small" 
                            variant={filtrosObservacion === "week" ? "contained" : "outlined"} 
                            startIcon={<ViewWeekIcon />}
                            onClick={() => handleFiltrosObservacion("week")}
                        >
                            Semana
                        </Button>
                        <Button 
                        sx={{ ml: 1 }}
                            size="small" 
                            variant={filtrosObservacion === "month" ? "contained" : "outlined"} 
                            startIcon={<CalendarMonthIcon />}
                            onClick={() => handleFiltrosObservacion("month")}
                        >
                            Mes
                        </Button>
                    </Box> 
                    
                </Box>
                
            </Container>
        </div>
    )
}