export const getPersonas = async(user, token) => {
  const response = await fetch('api/Personas/' + user.sub, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
  });

  const data = await response.json();
  return data;
}

export const postPersona = async(persona, user, token) => {
  persona.nombreCompleto = persona.nombreCompleto.toUpperCase();
  persona.nombreFantasia = persona.nombreFantasia.toUpperCase();
  persona.direccion = persona.direccion.toUpperCase();
  persona.correoElectronico = persona.correoElectronico.toUpperCase();

  const response = await fetch('api/Personas/' + user.sub, {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
      body: JSON.stringify(persona)
  });

  const data = await response.json();
  return data;
}

export const putPersona = async(persona, user, token) => {
  persona.nombreCompleto = persona.nombreCompleto.toUpperCase();
  persona.nombreFantasia = persona.nombreFantasia.toUpperCase();
  persona.direccion = persona.direccion.toUpperCase();
  persona.correoElectronico = persona.correoElectronico.toUpperCase();

  const response = await fetch('api/Personas/' + persona.personaID + '/' + user.sub, {
      method: "PUT",
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
      body: JSON.stringify(persona)
  });

  const data = await response.json();
  return data;
}

export const deletePersona = async(persona, user, token) => {
  const response = await fetch('api/Personas/' + persona.personaID + '/' + user.sub, {
      method: "DELETE",
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
  });

  const data = await response.json();
  return data;
}
