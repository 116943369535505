import React, { useState } from "react"

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { postTipoTarea, putTipoTarea } from "../../helpers/tipoTareas";
import FloatingActionButtons from "../fabButton";
import CheckIcon from '@mui/icons-material/Check';

import { useAuth0 } from "../../react-auth0-spa";

export default function NuevoTipoTarea({ tipoTareasSeleccionada, guardarTipoTarea }) {
    const { user, getTokenSilently } = useAuth0();
    const [tipoTarea, setTipoTarea] = useState(tipoTareasSeleccionada)
    
    const handleChange = (evento) => {
        evento.persist();
        const {name, value} = evento.target;
        setTipoTarea({...tipoTarea, [name]: value});
    }

    const guardar = async (e) => {
        e.preventDefault();
        const token = await getTokenSilently();

        if (tipoTarea.tipoTareaID > 0) {
            const result = await putTipoTarea(tipoTarea, user, token);
            guardarTipoTarea(result, "PUT")
        }
        else {
            const result = await postTipoTarea(tipoTarea, user, token);
            guardarTipoTarea(result, "POST")
        }
    }
    
    return (
        <Box
            component="form"
            sx={{ display: 'flex', flexWrap: 'wrap' }}
            autoComplete="off"
            onSubmit={guardar}
        >
            
            <TextField name="tipoTareaNombre"
                required
                autoFocus
                label="Nombre"
                value={tipoTarea.tipoTareaNombre}
                variant="standard" 
                fullWidth 
                margin="normal" 
                onChange={handleChange} 
                color="info"
                inputProps={{ style: { textTransform: "uppercase" } }}
            />

            <FloatingActionButtons type="submit" color="success"><CheckIcon /></FloatingActionButtons>
        </Box>
    )
}