export async function requestNotificationAccess() {
    if (checkNotificationPromise()) {
        const status = await Notification.requestPermission();
        return status;
    }
}

export async function getSubscription(applicationServerKey) {
    const serviceWorker = await navigator.serviceWorker.ready;

    const subscripcion = await serviceWorker.pushManager.getSubscription();
    if (subscripcion === null) {
        try {
            const sub = await serviceWorker.pushManager.subscribe({userVisibleOnly: true, applicationServerKey: urlB64ToUint8Array(applicationServerKey)});
            const values = fillSubscribeFields(sub);
            return {ok: true, values}
        }
        catch (e) {
            const error = "Unable to subscribe to push" + e;
            console.error(error);
            return {ok: false}
        }
    }

    const values = fillSubscribeFields(subscripcion);
    return {ok: true, values}
}

function fillSubscribeFields(sub) {
    return {
        endpoint: sub.endpoint,
        p256dh: arrayBufferToBase64(sub.getKey("p256dh")),
        auth: arrayBufferToBase64(sub.getKey("auth"))
    }
}

function arrayBufferToBase64(buffer) {
    const base64String = btoa(String.fromCharCode(...new Uint8Array(buffer)));
    return base64String;
}

function urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[ i ] = rawData.charCodeAt(i);
    }
    return outputArray;
}

function checkNotificationPromise() {
    try {
        Notification.requestPermission().then();
    } catch (e) {
        return false;
    }

    return true;
}

export async function getSubscriptionServer(user, token) {
    const response = await fetch('api/SubscripcionesNotificaciones/Subscribe/' + user.sub, {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
    });

    if (!response.ok) {
        // if (response.status === 401) {
        //     await authService.signIn();
        // }

        return
    }

    const data = await response.json();
    return data;
}

export async function subscribe(user, values, token) {
    try {
        const response = await fetch('api/SubscripcionesNotificaciones/Subscribe/' + user.sub, {
            method: 'POST',
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
            body: JSON.stringify(values)
        });

        if (!response.ok) {
            // if (response.status === 401) {
            //     await authService.signIn();
            // }

            return false
        }

        console.log(response);

        return true
    } catch (error) {
        console.error('ERROR en subscribe(): ', error)
        return false
    }
}

export async function unsubscribe(user, token) {
    try {
        const response = await fetch('api/SubscripcionesNotificaciones/Unsubscibe/' + user.sub, {
            method: 'POST',
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        });

        if (!response.ok) {
            // if (response.status === 401) {
            //     await authService.signIn();
            // }
            
            return false;
        }

        return true;
    } catch (error) {
        console.error('ERROR en unsubscribe(): ', error)
        return false
    }
}

export async function notificar(user, token, mensaje) {
    try {
        await fetch(`api/SubscripcionesNotificaciones/Notify?message=${mensaje}`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" , Authorization: `Bearer ${token}` },
        });
    }
    catch (error) {
        console.error('ERROR en notificar(): ', error)
    }

    return
}