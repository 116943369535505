import React, { Fragment, useRef, useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { Chip, Box, Grid, TextField, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button'


import CustomTable, { StyledTableCell, StyledTableRow } from "../customTable";
import BotonesAccionesTabla from "./botonesAccionesTabla";

const fechaAgrupacionTabla = (fecha) => {
    const dia = moment(fecha).format("dddd").substring(0, 1).toUpperCase() + moment(fecha).format("dddd").substring(1);
    const diaNumeroYMes = moment(fecha).format('DD [de] MMM YYYY');
    return `${dia} ${diaNumeroYMes}`;
}

export default function Table({ headers, lista, ultimaTareaEditada, esUsuarioTareas, estadosTareas, actions, servicios, tiposTareas, usuariosEmpresa }) {
    const [tareasAgrupadas, setTareasAgrupadas] = useState([]);
    const inputBuscarRef = useRef(); 
    
    const [filtros, setFiltros] = useState({ 
        estadoTareaID: 1, 
        fechaDesde: moment().startOf(window.localStorage.getItem('filtroMensualStart') || "week").format("yyyy-MM-DDTHH:mm"),
        fechaHasta: moment().endOf(window.localStorage.getItem('filtroMensualEdn') || "week").format("yyyy-MM-DDTHH:mm"),
        servicioID: 0,
        tipoTareaID: 0,
        usuarioID:'0',
        sinFecha: "0"
    })

    const { editar, notificar, eliminar, finalizar, filtrar } = actions;

    const theme = useTheme();
    const matches_md = useMediaQuery(theme.breakpoints.up('md'));
    const matches_sm = useMediaQuery(theme.breakpoints.up('sm'));

    const filtrarAgruparTareas = useCallback((value = '') => {
            let filtroPorEstado = lista 
            if(filtros.estadoTareaID > 0){
                filtroPorEstado = lista.filter(tarea => tarea.estadoTareaID === filtros.estadoTareaID)
            }
            const fechaTareasAgrupadas = filtroPorEstado.filter(tarea => (value !== '' && tarea.nombre.toLowerCase().includes(value.toLowerCase())) || value === '')
                    .sort((a, b) => {
                        if(moment(a.fechaTarea).isBefore(moment(b.fechaTarea)))
                            return -1
                        if(moment(a.fechaTarea).isSame(moment(b.fechaTarea)))
                            return 0
                        return 1
                    })
                    .reduce((acc, tarea) => {
                        const fechaTarea = fechaAgrupacionTabla(tarea.fechaTarea);
                        const existe = acc[fechaTarea];
                        if (!existe) {
                            acc[fechaTarea] = [tarea];
                        }
                        else {
                            acc[fechaTarea] = [...existe, tarea]
                        }
    
                        return acc
                    },
                    {});
    
            return fechaTareasAgrupadas

    }, [lista])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFiltros({...filtros, [name]: value})
    };

    useEffect(() => { 

            filtrar(filtros.estadoTareaID, filtros.fechaDesde, filtros.fechaHasta, filtros.servicioID, filtros.tipoTareaID, filtros.usuarioID, filtros.sinFecha) 

        }, [filtros])
    
    const handleChangeBuscar = () => {
        setTareasAgrupadas(filtrarAgruparTareas(inputBuscarRef.current.value));
    };

    useEffect(() => {
        setTareasAgrupadas(filtrarAgruparTareas(inputBuscarRef.current ? inputBuscarRef.current.value : null));
    }, [lista, filtrarAgruparTareas])

    return (
        <Fragment>
            <Box
                sx={{display: 'flex', flexWrap: 'wrap'}}
                autoComplete="off"
            >
                <Grid container spacing={{xs: 0, sm: 2}}>
                <Grid item xs={12} sm={2}>
                        <TextField
                            size="small"
                            name="sinFecha"
                            id="sinFecha"
                            select
                            label="Sin fecha"
                            value={filtros.sinFecha}
                            variant="standard"
                            margin={matches_sm ? "normal" : "dense"}
                            onChange={handleChange}
                            color="info"
                            sx={{ m: 0 }}
                            fullWidth
                        >
                            <MenuItem value="0">
                                Tareas por fecha
                            </MenuItem>
                            <MenuItem value="1">
                                Todas las tareas
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            size="small"
                            name="fechaDesde"
                            label="Fecha Desde"
                            type="datetime-local"
                            variant="standard"
                            value={filtros.fechaDesde}
                            fullWidth
                            margin={matches_sm ? "normal" : "dense"}
                            onChange={handleChange}
                            color="info"
                            sx={{ mt: 0}}
                            disabled={filtros.sinFecha == 1}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            size="small"
                            name="fechaHasta"
                            label="Fecha Hasta"
                            type="datetime-local"
                            variant="standard"
                            value={filtros.fechaHasta}
                            fullWidth
                            margin={matches_sm ? "normal" : "dense"}
                            onChange={handleChange}
                            color="info"
                            sx={{ mt: 0}}
                            disabled={filtros.sinFecha == 1}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            size="small"
                            name="estadoTareaID"
                            id="estadoTareaID"
                            select
                            label="Estado"
                            value={filtros.estadoTareaID}
                            variant="standard"
                            margin={matches_sm ? "normal" : "dense"}
                            onChange={handleChange}
                            color="info"
                            fullWidth
                            sx={{ mt: 0}}
                        >
                            <MenuItem value="0">
                                [TODOS LOS ESTADOS]
                            </MenuItem>
                            {estadosTareas.map((option) => (
                                <MenuItem key={option.estadoTareaID} value={option.estadoTareaID}>
                                    {option.estadoTareaNombre}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            size="small"
                            name="tipoTareaID"
                            id="tipoTareaID"
                            select
                            label="Tipo Tarea"
                            value={filtros.tipoTareaID}
                            variant="standard"
                            margin={matches_sm ? "normal" : "dense"}
                            onChange={handleChange}
                            color="info"
                            sx={{ m: 0 }}
                            fullWidth
                        >
                            <MenuItem value="0">
                                [TODOS LOS TIPOS TAREAS]
                            </MenuItem>
                            {tiposTareas.map((option) => (
                                <MenuItem key={option.tipoTareaID} value={option.tipoTareaID}>
                                    {option.tipoTareaNombre}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    <TextField
                        size="small"
                        name="servicioID"
                        id="servicioID"
                        select
                        label="Servicio"
                        value={filtros.servicioID}
                        variant="standard"
                        fullWidth
                        margin={matches_sm ? "normal" : "dense"}
                        onChange={handleChange}
                        color="info"
                        sx={{ m: 0 }}
                        >
                           <MenuItem value="0">
                                [TODOS LOS SERVICIOS]
                            </MenuItem>
                            {servicios.map((option) => (
                            <MenuItem key={option.servicioID} value={option.servicioID}>
                                {option.titulo}
                            </MenuItem>
                            ))}
                    </TextField>            
                   </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            size="small"
                            name="usuarioID"
                            id="usuarioID"
                            select
                            label="Usuarios"
                            value={filtros.usuarioID}
                            variant="standard"
                            margin={matches_sm ? "normal" : "dense"}
                            onChange={handleChange}
                            color="info"
                            sx={{ m: 0 }}
                            fullWidth
                        >
                            <MenuItem value="0">
                                [TODOS LOS USUARIOS]
                            </MenuItem>
                            {usuariosEmpresa.map((option) => (
                                <MenuItem key={option.usuarioID} value={option.usuarioID}>
                                    {option.email}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            size="small"
                            autoComplete="off"
                            name="buscar"
                            label="Buscar"
                            variant="standard"
                            margin={matches_sm ? "normal" : "dense"}
                            onKeyUp={handleChangeBuscar}
                            color="info"
                            inputProps={{style: {textTransform: "uppercase"}, ref: inputBuscarRef}}
                            fullWidth
                            sx={{ mt: 0 }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <CustomTable headers={headers}>
                {
                    Object.keys(tareasAgrupadas).map((group) => (
                        <Fragment key={group}>
                            <StyledTableRow className='title'>
                                <StyledTableCell colSpan={headers.length} sx={{px: 1, py: 1, fontWeight: 'bolder'}}>
                                    {group}
                                </StyledTableCell>
                            </StyledTableRow>
                            {tareasAgrupadas[ group ].map((tarea) => (
                                <StyledTableRow
                                    key={tarea.tareaID}
                                    sx={{position: "relative"}}
                                    className={`${ultimaTareaEditada === tarea.tareaID && 'ultimaTareaEditada'} ${tarea.estadoTareaID === 3 && 'tarea-finalizada'}`}
                                >
                                    <StyledTableCell sx={{zIndex: 5, px: 2, py: 1}}>
                                        <button className="buttonActionTable" onClick={() => editar(tarea)}></button>
                                        {tarea.nombre}
                                        <span style={{display: !matches_md ? 'flex' : 'inline-flex', gap: 10, marginLeft: !matches_md ? 0 : 10}}>
                                            {tarea.tipoTarea && <Chip size="small" label={tarea.tipoTarea.tipoTareaNombre} className="badge" color="primary" />}
                                            {tarea.servicioID !== 0 && <Chip size="small" variant="outlined" label={tarea.servicioTitulo} className="badge" />}
                                            {tarea.estadoTareaID === 1 && <Chip size="small" label={tarea.estadoTarea.estadoTareaNombre} className="badge" color="pendingTask" />}
                                            {tarea.estadoTareaID === 2 && <Chip size="small" label={tarea.estadoTarea.estadoTareaNombre} className="badge" color="warning" />}
                                            {tarea.estadoTareaID === 3 && <Chip size="small" label={tarea.estadoTarea.estadoTareaNombre} className="badge" color="success" />}
                                        </span>
                                    </StyledTableCell>
                                    <StyledTableCell sx={{width: 0, zIndex: 10, px: 1, py: 1}}>
                                        <BotonesAccionesTabla
                                            disabled={esUsuarioTareas}
                                            {...{
                                                tarea,
                                                notificar,
                                                eliminar,
                                                finalizar,
                                            }}
                                        />
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </Fragment>
                    ))
                }
            </CustomTable>

            {Object.keys(tareasAgrupadas).length === 0 && <p className='text-center'>NO EXISTEN TAREAS PARA MOSTRAR.</p>}
        </Fragment>
    )
}