import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Transition(props) {
    return <Slide {...props} direction="right" />;
}

export default function CustomSnackbar({mensaje, tipo, open, handleClose}) {
    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}
                TransitionComponent={Transition} // top, left, down, right.
            >
                <Alert onClose={handleClose} severity={tipo} sx={{ width: '100%' }}>
                    {mensaje}
                </Alert>
            </Snackbar>
        </Stack>
    );
}