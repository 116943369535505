// import React, { Component } from 'react';
// import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
// import { Link } from 'react-router-dom';
// import './NavMenu.css';

// export class NavMenu extends Component {
//   static displayName = NavMenu.name;

//   constructor (props) {
//     super(props);

//     this.toggleNavbar = this.toggleNavbar.bind(this);
//     this.state = {
//       collapsed: true
//     };
//   }

//   toggleNavbar () {
//     this.setState({
//       collapsed: !this.state.collapsed
//     });
//   }

//   render () {
//     return (
//       <header>
//         <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
//           <Container>
//             <NavbarBrand tag={Link} to="/">MTReact</NavbarBrand>
//             <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
//             <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
//               <ul className="navbar-nav flex-grow">
//                 <NavItem>
//                   <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
//                 </NavItem>
//                 <NavItem>
//                   <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>
//                 </NavItem>
//                 <NavItem>
//                   <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>
//                 </NavItem>
//                 <NavItem>
//                   <NavLink tag={Link} className="text-dark" to="/login">login</NavLink>
//                 </NavItem>
//                 <NavItem>
//                   <NavLink tag={Link} className="text-dark" to="/logout">logout</NavLink>
//                 </NavItem>
//                 <NavItem>
//                   <NavLink tag={Link} className="text-dark" to="/profile">profile</NavLink>
//                 </NavItem>
//               </ul>
//             </Collapse>
//           </Container>
//         </Navbar>
//       </header>
//     );
//   }
// }






import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
// import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
// import LensBlurRoundedIcon from '@mui/icons-material/LensBlurRounded';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import Settings from '@mui/icons-material/Settings';
// import WorkIcon from '@mui/icons-material/Work';
import GroupsIcon from '@mui/icons-material/Groups';
import Person from '@mui/icons-material/Person';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { GetPermisoUsuarioActual, logoEmpresa, getUser } from '../helpers/empresa';


import { useAuth0 } from "../react-auth0-spa";


import './NavMenu.css'
import { transform } from 'lodash';
const pages = [['Servicios', '/servicios'],['Calendario', '/tareas/indexAgenda'], ['Tareas', '/tareas/indexTabla'], ['Consultas', '/tareas/visualizarObservaciones'], ['Personas', '/personas']];

export const NavMenu = ({ handleTheme }) => {
    const { logout, loginWithRedirect, isAuthenticated, user, getTokenSilently } = useAuth0();

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [archivos, setArchivos] = React.useState(null);
    const [usuarioTareas, setUsuarioTareas] = React.useState(false);
    const location = useLocation();
    const usuario = React.useRef(null);
    const logo = React.useRef(null);
    const theme = useTheme();
    const history = useHistory();

    React.useEffect(() => {
        async function GetLogo() {
            const token = await getTokenSilently();
            const respuesta = await logoEmpresa(user, token);
            if (respuesta) {
                logo.current = respuesta;
            }
        }
        
        async function GetUser() {
            const token = await getTokenSilently();
            const respuesta = await getUser(user, token);
            if (respuesta) {
                usuario.current = respuesta;
            }
        }

        GetUser();
        GetLogo();
        BuscarPermisoUsuarioActual();
    }, [])
    const BuscarPermisoUsuarioActual = async () => {
        const token = await getTokenSilently();
        GetPermisoUsuarioActual(user, token)
            .then(respuestaPermiso => {
                setUsuarioTareas(respuestaPermiso.permisoID === 1);
            })
    }
  
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleOpenArchivos = (event) => {
        setArchivos(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleCloseArchivos = () => {
        setArchivos(null);
    };

    return (
        <AppBar position="sticky">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ mr: 2, display: { xs: 'none', md: 'flex', cursor: 'pointer' } }}
                        onClick={() => {
                            history.push('/');
                        }}
                    >
                        <img src={logo.current ? logo.current : "../../img/LogoSoloBlanco.png"} alt="logo" className='logo-menu' />
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {
                                theme.palette.mode === 'light' ? (
                                <>
                                <Button
                                onClick={handleOpenArchivos}
                                sx={[
                                    {
                                        mx: 1, my: 0,textTransform: 'initial', fontSize:17, color: 'black',  opacity: .85, display: 'block', transition: 'all .15s ease-in-out',
                                        '&:hover': { opacity: 1, transform: 'scale(1.1)' }
                                    }
                                ]}
                                >
                                Archivos<ArrowDropDownIcon /> 
                                </Button>
                                        </>
                                    ) : (
                                <>
                                            <Button
                                onClick={handleOpenArchivos}
                                sx={[
                                    {
                                        mx: 1, my: 0,textTransform: 'initial', fontSize:17, color: 'white',  opacity: .85, display: 'block', transition: 'all .15s ease-in-out',
                                        '&:hover': { opacity: 1, transform: 'scale(1.1)' }
                                    }
                                ]}
                                >
                                Archivos<ArrowDropDownIcon /> 
                                </Button>
                                </>
                                    )
                                }
                            
                            
                            {pages.map((page) => (
                                <MenuItem component={Link} to={page[1]} key={page} onClick={handleCloseNavMenu}>
                                    {
                                        theme.palette.mode === 'light' ? (
                                            <>
                                            <Typography textAlign="center" sx={[
                                    {
                                        mx: 1, my: 0,textTransform: 'initial', fontSize:17, color: 'black',  opacity: .85, display: 'block', transition: 'all .15s ease-in-out',
                                        '&:hover': { opacity: 1, transform: 'scale(1.1)' }
                                    }
                                ]}>{page[0]}</Typography>
                                            </>
                                        ) : (
                                            <>
                                            <Typography textAlign="center" sx={[
                                    {
                                        mx: 1, my: 0,textTransform: 'initial', fontSize:17, color: 'white',  opacity: .85, display: 'block', transition: 'all .15s ease-in-out',
                                        '&:hover': { opacity: 1, transform: 'scale(1.1)' }
                                    }
                                ]}>{page[0]}</Typography>
                                            </>
                                        )
                                    }
                                    
                                </MenuItem>
                                
                            ))}
                            
                            
                        </Menu>
                        
                    </Box>
                    
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none', cursor: 'pointer' } }}
                        onClick={() => {
                            history.push('/');
                        }}
                    >
                        <img src="../../img/LogoSoloBlanco.png" alt="logo" className='logo-menu' />
                    </Typography>

                    <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                            <Button
                                onClick={handleOpenArchivos}
                                sx={[
                                    {
                                        mx: 1, my: 2, color: 'white', opacity: .85, display: 'block', transition: 'all .15s ease-in-out',
                                        '&:hover': { color: '#fff', opacity: 1, transform: 'scale(1.1)' }
                                    }
                                ]}
                            >
                                ARCHIVOS<ArrowDropDownIcon />
                            </Button>
                            
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={archivos}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(archivos)}
                            onClose={handleCloseArchivos}
                        >
                            
                            <MenuItem onClick={() => {
                                    handleCloseArchivos();
                                    handleCloseNavMenu();
                                    history.push('/tipoTareas');
                                }}>
                                <Typography textAlign="center">TIPO TAREAS</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => {
                                    handleCloseArchivos();
                                    handleCloseNavMenu();
                                    history.push('/localidades');
                                }}>
                                <Typography textAlign="center">LOCALIDADES</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>


                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                component={Link}
                                to={page[1]}
                                key={page}
                                onClick={handleCloseNavMenu}
                                sx={[
                                    {
                                        mx: 1, my: 2, color: 'white', opacity: .85, display: 'block', transition: 'all .15s ease-in-out',
                                        '&:hover': { color: '#fff', opacity: 1, transform: 'scale(1.1)' }
                                    },
                                    location.pathname === page[1] && { color: '#fff', opacity: 1, transform: 'scale(1.1)' }
                                ]}
                            >
                                {page[0]}
                            </Button>
                        ))}
                    </Box>

                    

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Abrir Configuración">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 , width:'50%' }}>
                            <img src={user.picture} style={{ height: 'auto', maxWidth: '56px', borderRadius: '28px' }} />
                         
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >


                            <MenuItem>
                                <ListItemIcon>
                                    <Person fontSize="small" />
                                </ListItemIcon>
                                <Typography textAlign="center">{usuario.current}</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => {
                                handleCloseUserMenu();
                                history.push('/configuracion');
                            }}>
                                <ListItemIcon>
                                    <Settings fontSize="small" />
                                </ListItemIcon>
                                <Typography textAlign="center">Configuraci&oacute;n</Typography>
                            </MenuItem>
                            {
                                usuarioTareas && 
                                <MenuItem onClick={() => {
                                    handleCloseUserMenu();
                                    history.push('/empresa');
                                }}>
                                    <ListItemIcon>
                                        <GroupsIcon/>
                                    </ListItemIcon>
                                    <Typography textAlign="center">Usuarios del Sistema</Typography>
                                </MenuItem>
                            }
                            <MenuItem onClick={handleTheme}>
                                {
                                    theme.palette.mode === 'light' ? (
                                        <>
                                            <ListItemIcon>
                                                <DarkModeIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography textAlign="center">Tema Oscuro</Typography>
                                        </>
                                    ) : (
                                        <>
                                            <ListItemIcon>
                                                <LightModeIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography textAlign="center">Tema Claro</Typography>
                                        </>
                                    )
                                }
                            </MenuItem>

                            <Divider />

                            {
                                isAuthenticated
                                ?   <MenuItem onClick={() => logout({})}>
                                        <ListItemIcon>
                                            <Logout fontSize="small" />
                                        </ListItemIcon>
                                        <Typography textAlign="center">Cerrar Sesión</Typography>
                                    </MenuItem>

                                :   <MenuItem onClick={() => loginWithRedirect()}>
                                        <ListItemIcon>
                                            <Logout fontSize="small" />
                                        </ListItemIcon>
                                        <Typography textAlign="center">Iniciar Sesión</Typography>
                                    </MenuItem>
                            }
                        </Menu>
                    </Box>
                   
                </Toolbar>
            </Container>
        </AppBar>
    );
};