// import 'bootstrap/dist/css/bootstrap.css';
// import React from 'react';
// import ReactDOM from 'react-dom';
// import { BrowserRouter } from 'react-router-dom';
// import App from './App';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';

// import history from "./utils/history";
// import { Auth0Provider } from "@auth0/auth0-react";

// const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
// const rootElement = document.getElementById('root');

// ReactDOM.render(
//   <Auth0Provider
//     domain="mistareas.us.auth0.com"
//     clientId="t6bFYk2u1gLMxLWDJvPJaheveAS18GvZ"
//     redirectUri={window.location.origin}
//     audience="https://mistareas.us.auth0.com/api/v2/"
//     scope="read:current_user update:current_user_metadata"
//   >
//     <BrowserRouter basename={baseUrl}>
//       <App />
//     </BrowserRouter>,
//   </Auth0Provider>,
//   rootElement);







import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import history from "./utils/history";
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import * as settings from "./helpers/settings";
import { Auth0Provider } from "./react-auth0-spa";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const onRedirectCallback = (appState) => {
    history.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
};

settings
    .getAuthSettings()
    .then((authSettings) => {
        ReactDOM.render(
            <Auth0Provider
                domain={authSettings.domain}
                client_id={authSettings.clientId}
                audience={authSettings.audience}
                redirect_uri={window.location.origin}
                onRedirectCallback={onRedirectCallback}
            >
                <BrowserRouter basename={baseUrl} history={history}>
                    <App />
                </BrowserRouter>
            </Auth0Provider>,
            rootElement
        );
    })
    .catch((error) => {
        console.log(error);
    });



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
