import moment from "moment";
import "moment/locale/es"

export const esDiaActual = (dia) => {
  return moment().isSame(moment(dia), 'day')
}

export const esDiaAnterior = (dia) => {
  return moment().isSame(moment(dia).add(1, 'days'), 'day')
}

export const esDiaPosterior = (dia) => {
  return moment().isSame(moment(dia).subtract(1, 'days'), 'day')
}

export const esPrimerDiaSemana = (dia) => {
  return moment(dia).day() === 0
}

export const horasDelDia = () => {
  const horas = [];
  for(let i = 0; i < 24; i++) {
    const hora = i < 10 ? `0${i}:00` : `${i}:00`;
    horas.push(hora)
  }

  return horas
}

export const diasPorSemana = (semana = null) => {
  const dias = [];
  const comienzoSemana = semana ? moment().week(semana) : moment();
  for(let i = 0; i < 7; i++) {
    const dia = comienzoSemana.day(i)
    const fecha = `${dia.date()} de ${dia.format('MMMM')}`;
    const nombre = dia.format('dddd').substring(0, 1).toUpperCase() + dia.format('dddd').substring(1);
    dias.push({
      fecha,
      nombre,
      diaSemana: dia.day(),
      dia: dia.format()
    })
  }

  return dias
}

export const esMismaHora = (fecha, hora, dia) => {
  const horaFecha = moment(fecha).hours() < 10 ? `0${moment(fecha).hours()}:00` : `${moment(fecha).hours()}:00`;
  return horaFecha === hora
}

export const estaDentroHora = (fechaInicio, fechaFin, hora, dia) => {
  // if(moment(fechaInicio).isBefore(fechaFin) && )
  if(moment(dia).isBefore(fechaFin, 'day')) {
    if(moment(dia).isSame(fechaInicio, 'day')) {
      return parseInt(hora) >= moment(fechaInicio).hours()
    }
    if(moment(dia).isAfter(fechaInicio, 'day')) {
      return true
    }
  }

  if(moment(dia).isAfter(fechaInicio, 'day') && moment(dia).isSame(fechaFin, 'day')) {
    return parseInt(hora) <= moment(fechaFin).hours()
  }

  return parseInt(hora) >= moment(fechaInicio).hours() && parseInt(hora) <= moment(fechaFin).hours()
}