import React, {useEffect, useState} from 'react';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import Tooltip from "@mui/material/Tooltip";

import {useTheme, useMediaQuery} from '@mui/material'
import { GetPermisoUsuarioActual } from '../../helpers/empresa';
import { useAuth0 } from "../../react-auth0-spa";

export default function BotonesAccionesTabla({ persona, eliminar }) {
    const { user, getTokenSilently } = useAuth0();
    const theme = useTheme();
    const match_md = useMediaQuery(theme.breakpoints.up('md'));
    const [usuarioTareas, setUsuarioTareas] = useState(false);
    useEffect(() => {
        BuscarPermisoUsuarioActual();
    
      return () => {
      }
    }, [])
    
    
    const BuscarPermisoUsuarioActual = async () => {
        const token = await getTokenSilently();
        GetPermisoUsuarioActual(user, token)
            .then(respuestaPermiso => {
                setUsuarioTareas(respuestaPermiso.permisoID === 3);
            })
    }

    return (
        <div style={{display: 'flex', gap: 5}}>
            <Tooltip title="Eliminar" arrow disableInteractive>
                <span>
                    <IconButton 
                        onClick={() => eliminar(persona)} 
                        aria-label="delete" 
                        size={match_md ? "medium" : "small"}
                        disabled={usuarioTareas}
                    >
                        <DeleteIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </div>
    )
}