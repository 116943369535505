import moment from 'moment'

export const filtrarTareasPorDia = (tarea, dia) => {

  // console.log({ 
  //   inicio: tarea.fechaTarea, 
  //   fin: tarea.fechaTareaFin, 
  //   dia, 
  //   isBetween: moment(dia).isBetween(tarea.fechaTarea, tarea.fechaTareaFin, 'day', '[]') 
  // })

  // return moment(tarea.fechaTarea).isSame(dia, 'day')
  return moment(dia).isBetween(tarea.fechaTarea, tarea.fechaTareaFin, 'day', '[]')
}