// import { handleJsonResponse, handleError } from "./apiUtils";

const baseUrl = "api/weatherforecast";

export async function getWeatherForecast(token) {
    const response = await fetch(baseUrl, {
        headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
    });

    const data = await response.json();
    return data;
}