import React, { Fragment, useRef, useState, useCallback, useEffect } from 'react';
import { Box, Grid, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import CustomTable, { StyledTableCell, StyledTableRow } from "../customTable";
import BotonesAccionesTabla from "./botonesAccionesTabla";
import { GetPermisoUsuarioActual } from '../../helpers/empresa';
import { useAuth0 } from "../../react-auth0-spa";

export default function Table({ headers, lista, actions }) {
    const inputBuscarRef = useRef();
    const { user, getTokenSilently } = useAuth0();
    const [usuarioTareas, setUsuarioTareas] = useState(false);
    const [personas, setPersonas] = useState([]);

    const { editar, eliminar } = actions;


    const theme = useTheme();
    
    const matches_sm = useMediaQuery(theme.breakpoints.up('sm'));

    const filtrarAgruparTareas = useCallback((value = '') => {
        const fechaTareasAgrupadas = lista.filter(persona => (value !== '' && persona.nombreCompleto.toLowerCase().includes(value.toLowerCase())) || value === '')
                .reduce((acc, persona) => {
                    const personaNombre = (persona.nombreCompleto);
                    const existe = acc[personaNombre];
                    if (!existe) {
                        acc[personaNombre] = [persona];
                    }
                    else {
                        acc[personaNombre] = [...existe, persona]
                    }
                    return acc
                },
                {});

        return fechaTareasAgrupadas
    }, [lista])

    const handleChangeBuscar = () => {
        setPersonas(filtrarAgruparTareas(inputBuscarRef.current.value));
    };

    useEffect(() => {
        BuscarPermisoUsuarioActual();
        setPersonas(filtrarAgruparTareas(inputBuscarRef.current ? inputBuscarRef.current.value : null));
    }, [lista, filtrarAgruparTareas])

    const BuscarPermisoUsuarioActual = async () => {
        const token = await getTokenSilently();
        GetPermisoUsuarioActual(user, token)
            .then(respuestaPermiso => {
                setUsuarioTareas(respuestaPermiso.permisoID === 3);
            })
    }
    

    return (
        <Fragment>
            <Box
                sx={{display: 'flex', flexWrap: 'wrap'}}
                autoComplete="off"
            >
                <Grid container spacing={{xs: 0, sm: 2}}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            size="small"
                            autoComplete="off"
                            name="buscar"
                            label="Buscar"
                            variant="standard"
                            margin={matches_sm ? "normal" : "dense"}
                            onKeyUp={handleChangeBuscar}
                            color="info"
                            inputProps={{style: {textTransform: "uppercase"}, ref: inputBuscarRef}}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Box>
            <CustomTable headers={headers}>
                {
                    Object.keys(personas).map((group) => (
                        <Fragment key={group}>
                            <StyledTableRow className='title'>
                            </StyledTableRow>
                            {personas[ group ].map((persona) => (
                                <StyledTableRow
                                    key={persona.personaID}
                                    sx={{position: "relative"}}  
                                >
                                    <StyledTableCell sx={{zIndex: 5, width:250}} >
                                    <button className="buttonActionTable" onClick={() => editar(persona)}disabled={usuarioTareas}></button>
                                        {persona.nombreCompleto}
                                    </StyledTableCell>
                                    <StyledTableCell sx={{width:200}}>
                                        {persona.nombreFantasia}
                                    </StyledTableCell>
                                    <StyledTableCell sx={{width:100}}>
                                        {persona.dni}
                                    </StyledTableCell>
                                    <StyledTableCell sx={{width:120}}>
                                        {persona.celular}
                                    </StyledTableCell>
                                    <StyledTableCell sx={{width:120}}>
                                        {persona.direccion}
                                    </StyledTableCell>
                                    <StyledTableCell sx={{width:200}}>
                                        {persona.correoElectronico}
                                    </StyledTableCell>
                                    <StyledTableCell sx={{zIndex:9, width:100}}>
                                        {persona.localidad.nombre}
                                    </StyledTableCell>
                                    <StyledTableCell sx={{width: 0, zIndex: 10, px: 1, py: 1}}>
                                        <BotonesAccionesTabla
                                            {...{
                                                persona,
                                                eliminar,
                                            }}
                                        />
                                    </StyledTableCell>
                                   
                                </StyledTableRow>
                            ))}
                        </Fragment>
                    ))
                }
            </CustomTable>

            {Object.keys(personas).length === 0 && <p className='text-center'>NO EXISTEN PERSONAS PARA MOSTRAR.</p>}
        </Fragment>
    )
}