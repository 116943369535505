import React, { useRef, useCallback, useEffect, useState } from "react"
//Icons
import AddIcon from '@mui/icons-material/Add';

//Custom Components
import FloatingActionButtons from "../fabButton";
import FullScreenDialog from "../fullScreenDialog";
import CustomDialog from "../customDialog";
import CustomTable, { StyledTableCell, StyledTableRow } from "../customTable";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomSnackbar from '../customSnackbar';
//Views
import Table from "./tabla";


//Helpers
import { getLocalidades } from "../../helpers/localidades";
import { getPersonas, deletePersona } from "../../helpers/persona";
import { GetPermisoUsuarioActual } from '../../helpers/empresa';

import { useAuth0 } from "../../react-auth0-spa";
import NuevaPersona from "./nuevaPersona";


export default function Personas() {
    const { user, getTokenSilently } = useAuth0();
    const [state, setState] = useState({ loading: false, view: 'table' });
    const initialCustomDialog = { show: false, title: "", text: "", buttons: [] };
    const [customDialog, setCustomDialog] = useState(initialCustomDialog);
    const [customSnackbar, setCustomSnackbar] = React.useState(undefined);
    const [usuarioTareas, setUsuarioTareas] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [personas, setPersonas] = useState([]);
    const localidades = useRef([]);

    const initialState = {
        personaID: 0,
        nombreCompleto: '',
        nombreFantasia: '',
        dni: '',
        celular: '',
        direccion: '',
        correoElectronico: '',
        localidadID: 0,
    }
    const [personaSeleccionada, setPersonaSeleccionada] = useState(initialState)

    useEffect(() => {
        obtenerPersonas();
        BuscarPermisoUsuarioActual();
        async function InitialFetch() {
            try {
                const token = await getTokenSilently();
                const localidadesResponse = await getLocalidades(user, token);
                localidades.current = localidadesResponse;
            } catch(error) {
                console.error('Ocurrio un error en Localidad - InitialFetch: \n', error);
            }
        }
        InitialFetch();
        return function() {
            localidades.current = [];
        }
    }, [])
     const obtenerPersonas = async () => {
        const token = await getTokenSilently();
        getPersonas(user, token)
            .then(respuestaPersonas => {
                setPersonas(respuestaPersonas);
            })
    };
    const BuscarPermisoUsuarioActual = async () => {
        const token = await getTokenSilently();
        GetPermisoUsuarioActual(user, token)
            .then(respuestaPermiso => {
                setUsuarioTareas(respuestaPermiso.permisoID === 3);
            })
    }
    const handleOpenDialog = useCallback(() => setOpenDialog(true), []);

    const handleClose = useCallback(() => {
        setOpenDialog(false)
        setPersonaSeleccionada(initialState)
    }, [initialState]);


    const eliminarPersona = persona => {
        setCustomDialog({
            show: true, 
            title: "Eliminar", 
            text: "¿Está seguro que desea eliminar esta Persona?", 
            buttons: [
                <Button onClick={() =>setCustomDialog(initialCustomDialog)}>Cancelar</Button>,
                <Button color="error" onClick={() => eliminar(persona)}>Aceptar</Button>,
            ]
        });
    };

    const eliminar = async (persona)  => {
        const token = await getTokenSilently();
        const result = await deletePersona(persona, user, token,);
        if (result.status === 'Success') {
            guardarPersona(persona, "DELETE")
        }

        setCustomSnackbar({
            mensaje: result.message,
            tipo: result.status.toLowerCase(),
            direccion: "right",
            open: true,
            handleClose: () => setCustomSnackbar(prevState => ({...prevState, open: false}))
        });

        setCustomDialog(initialCustomDialog)
    }

     const editarPersona = persona => {
        setPersonaSeleccionada(persona);
        setOpenDialog(true);
      
    };

    const guardarPersona = useCallback((persona, action) => {
        switch (action) {
            case "POST":
                setPersonas([...personas, persona]);
                break;
            case "PUT":
                setPersonas([...personas.map(p => p.personaID === persona.personaID ? { ...persona } : p)]);
                break;
            case "DELETE":
                setPersonas([...personas.filter(p => p.personaID !== persona.personaID)]);
                setCustomDialog(initialCustomDialog)
                break;
        }

        handleClose();

    }, [initialCustomDialog])

    return (
        <div>
            <h1 className="titulosVistas">Listado de Personas</h1>
            <hr />
            
             {/* <CustomTable headers={["Razon social", "Nombre fantasía", "DNI","Telefono", "Dirección", "Correo electronico", "Localidad", ""]} rows={personas}>
                {personas.map((persona) => (
                    <StyledTableRow sx={{position: "relative"}} key={persona.personaID}>
                        <StyledTableCell sx={{zIndex:5, width:200}}>
                            <button className="buttonActionTable" onClick={() => editarPersona(persona)} disabled={usuarioTareas}></button>{persona.nombreCompleto}
                        </StyledTableCell>
                        <StyledTableCell sx={{width:200}}>
                           {persona.nombreFantasia}
                        </StyledTableCell>
                        <StyledTableCell sx={{width:100}}>
                           {persona.dni}
                        </StyledTableCell>
                        <StyledTableCell sx={{width:120}}>
                           {persona.celular}
                        </StyledTableCell>
                        <StyledTableCell sx={{width:120}}>
                           {persona.direccion}
                        </StyledTableCell>
                        <StyledTableCell sx={{width:200}}>
                           {persona.correoElectronico}
                        </StyledTableCell>
                        <StyledTableCell sx={{zIndex:9, width:100}}>
                           {persona.localidad.nombre}
                        </StyledTableCell>
                        <StyledTableCell sx={{width: 0, zIndex:10, px: 1, py: 1}}>
                            <Tooltip title="Eliminar" arrow disableInteractive>
                                <span>
                                    <IconButton onClick={() => eliminarPersona(persona)} aria-label="delete" disabled={usuarioTareas}>
                                        <DeleteIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
            </CustomTable> */}

            {state.view === 'table' && (
                <Table

                    headers={["Razón social", "Nombre fantasía", "DNI","Teléfono", "Dirección", "Correo electrónico", "Localidad", ""]} 
                    lista={personas}
                    actions={{ 
                        editar: editarPersona,
                        eliminar: eliminarPersona,
                        
                    }} 
                />
            )}

            <FloatingActionButtons onClick={handleOpenDialog} color="primary" position="fixed" disabled={usuarioTareas}>
                <AddIcon />
            </FloatingActionButtons>

            <CustomDialog {...customDialog} />

            <FullScreenDialog
                open={openDialog}
                handleClose={handleClose}
                titulo="Datos de la Persona"
            >
                <NuevaPersona 
                    personaSeleccionada={personaSeleccionada} 
                    guardar={guardarPersona}
                    localidades={localidades.current}
                    setCustomSnackbar={setCustomSnackbar}
                />
            </FullScreenDialog>
            <CustomSnackbar {...customSnackbar} ></CustomSnackbar>
        </div>
    )
}
