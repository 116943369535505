import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function CustomAutocomplete({elements, elementsActive, label, ...props}) {
    return (
        <Autocomplete
            {...props}
            multiple
            id="autocomplete"
            options={elements}
            getOptionLabel={(option) => option.email}
            filterSelectedOptions
            value={elementsActive || []}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label={label ? label : "Usuarios Asignados"}
                    placeholder="Usuario"
                    color="info"
                />
            )}
        />
    );
}