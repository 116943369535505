import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';

export default function FloatingActionButtons({children, position, bottom, ...props}) {
    const fabStyle = {
        position: position || 'absolute',
        bottom: bottom || 16,
        right: 16,
        zIndex: 1000
    };

    return (
        <Fab {...props} sx={fabStyle} aria-label="add">
            {children}
        </Fab>
    );
}