import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Container = styled('section')`
  width: 80%;
  height: 90vh;
  min-height: 240px; 
  overflow-y: scroll;
  user-select: none;
  border-radius: 10px;
  position: relative;
  box-shadow: ${({theme}) => theme.shadows[2]};
  
  &::-webkit-scrollbar {
    display: none
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: auto;
    height: 70vh;
    min-height: 500px;
  }
`;

export const ContainerHeader = styled('header')`
  position: sticky; 
  top: 0;
  box-shadow: 0 2px 5px rgba(0,0,0, .5);
  z-index: 999;
`;

export const Header = styled(Box)`
  background-color: ${({theme}) => theme.palette.primary.main};
  padding: ${({theme}) => `${theme.spacing(2)}px`};
  color: ${({ theme }) => theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.palette.common.white};
  text-align: center;
  border-radius: 0;
  border: 3px solid transparent;
  min-width: 60px;
  &.currentDay {
    background-color: ${({ theme }) => theme.palette.mode === 'dark' ? '#080808' : '#F5F5F5'};
    border-bottom-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const ItemTarea = styled(Box)`
  --bg-color: ${({ theme }) => theme.palette.background.paper};
  ${({ theme }) => theme.typography.body2}
  background-color: var(--bg-color);
  padding: 0 2px;
  color: ${({ theme }) => theme.palette.text.secondary};
  border-radius: 0;
  width: 100%;
  /* height: 100px; */
  ${({ hasElements }) => hasElements ? 'height: 100px;' : 'height: auto;'}
  overflow: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none
  }

  & .hour {
    background: var(--bg-color);
    background: linear-gradient(90deg, var(--bg-color), transparent 50%);
    font-size: 9px;
    position: sticky;
    top: 0;
    display: inline-block;
    width: 100%;
    z-index: 2;
    padding-left: 2px;
  }

  & .task_container {
    padding: 2px; 
    display: flex; 
    gap: 2px; 
    flex-direction: column;
    & .MuiChip-root {
      font-size: 10px;
      justify-content: start;
      z-index: 1;
    }
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 100px;
  }
`;

export const Columna = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderTop: 0,
  borderStyle: 'solid',
  borderColor: theme.palette.mode === 'dark' ? '#484848' : '#d1d1d1',
  borderLeftWidth: 0,
  borderRightWidth: '1px',
  borderBottomWidth: '1px',

  [theme.breakpoints.up('md')]: {
    ['&.esDiaActual']: {
      borderColor: theme.palette.primary.main, 
      borderLeftWidth: '3px',
      borderRightWidth: '3px'
    },
  
    ['&.esDiaAnterior']: {
      borderRightWidth: 0
    },
    
    ['&.esPrimerDiaSemana:first-of-type']: {
      borderLeftWidth: '1px',
    },
  },

  ['& .MuiBox-root']: {
    borderBottom: '1px dotted',
    borderBottomColor: theme.palette.mode === 'dark' ? '#484848' : theme.palette.text.secondary,
    ['&:last-of-type']: {
      borderBottom: 0,
    }
  }
}));


export const BotonTarea = styled('button')(({ theme }) => ({
  border: '1px', 
  borderStyle: 'solid',
  borderColor: theme.palette.grey[800],
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[300],
  color: theme.palette.mode === 'dark' ? theme.palette.grey[50] : theme.palette.grey[800],
  cursor: 'pointer', 
  fontSize: '9px', 
  padding: '4px', 
  textAlign: 'left',
  borderRadius: '.5rem',
  // opacity: .8,
  transition: 'all .15s ease-in-out',
  // filter: theme.palette.mode === 'dark' ? 'brightness(90%)' : 'brightness(100%)',

  // ['&:hover']: {
  //   filter: theme.palette.mode === 'dark' ? 'brightness(100%)' : 'brightness(90%)',
  // },

  ['&.primary']: {
    borderColor: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    ['&:hover']: {
      backgroundColor: theme.palette.primary[theme.palette.mode],
    },
    ['&:disabled']: {
      color: theme.palette.primary.main
    },
  },
  ['&.success']: {
    borderColor: theme.palette.success.light,
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    ['&:hover']: {
      backgroundColor: theme.palette.success[theme.palette.mode],
    },
    ['&:disabled']: {
      color: theme.palette.success.main
    },
  },

  ['&:active']: {
    transform: 'scale(.95)'
  },

  ['&:disabled']: {
    backgroundColor: 'transparent',
    filter: 'none',
    cursor: 'default',
    transform: 'none',
    color: theme.palette.grey[800],
  },

  ['& .MuiSvgIcon-root']: {
    fontSize: '1rem',
  }
}));

export const BotonTareaTitulo = styled('p')(({ theme }) => ({
  margin: 0,
  marginLeft: '4px',
  flex: 'initial', 
  width: '100%', 
  bgcolor: 'red', 
  textAlign: 'left', 
  whiteSpace: 'nowrap', 
  overflow: 'hidden', 
  textOverflow: 'ellipsis',
}));

export const ContainerFilters = styled('section')(({theme}) => ({
  my: 1, 
  display: 'flex', 
  flexDirection: theme.breakpoints.up('sm') ? 'row' : 'column', 
  justifyContent: 'start', 
  alignItems: 'center', 
  gap: 2
}));

export const StateTaskContainer = styled(Box)`
  display: flex; 
  justify-content: center; 
  gap: 1;
  padding: 10px 0;

  & .MuiChip-root {
    font-size: 10px;
    height: 20px;
    & .MuiSvgIcon-root {
      font-size: 14px;
    }
  }
`;