import React, { useState } from "react"

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { postPermisoUsuario, putPermisoUsuario } from "../../helpers/empresa";
import FloatingActionButtons from "../fabButton";
import CheckIcon from '@mui/icons-material/Check';
import { MenuItem } from "@mui/material";

import { useAuth0 } from "../../react-auth0-spa";


export default function NuevoPermisoUsuario({ usuarioSeleccionado, guardarPermisoUsuario,tipoPermisos }) {
    const { user, getTokenSilently } = useAuth0();
    const [permisoUsuario, setPermisoUsuario] = useState(usuarioSeleccionado)
   
    const handleChange = (evento) => {
        const {name, value} = evento.target;
        setPermisoUsuario({...permisoUsuario, [name]: value});
    }

    const guardar = async (e) => {
        e.preventDefault();
        const token = await getTokenSilently();

        if (permisoUsuario.permisoUsuarioID > 0) {
            const result = await putPermisoUsuario(user, token, permisoUsuario);
            guardarPermisoUsuario(result, "PUT")
        }
        else {
            const result = await postPermisoUsuario(user, token, permisoUsuario);
            guardarPermisoUsuario(result, "POST")                     
        }
    }
    
    return (
        <Box
            component="form"
            sx={{ display: 'flex', flexWrap: 'wrap' }}
            autoComplete="off"
            onSubmit={guardar}
        >
            <TextField name="nombreUsuario"
                required
                autoFocus
                label="Nombre de Usuario"
                disabled = {permisoUsuario.permisoUsuarioID !== 0}
                value={permisoUsuario.nombreUsuario}
                variant="standard" 
                fullWidth 
                margin="normal" 
                onChange={handleChange} 
                color="info"
            />

            <TextField name="email"
                required
                label="Email"
                value={permisoUsuario.email}
                disabled = {permisoUsuario.permisoUsuarioID !== 0}
                variant="standard" 
                fullWidth 
                margin="normal" 
                onChange={handleChange} 
                color="info"
                inputProps={{ style: { textTransform: "lowercase" } }}
            />

            {/* <TextField 
                name="usuarioID"
                required
                label="Id Usuario"
                disabled = {permisoUsuario.permisoUsuarioID !== 0}
                value={permisoUsuario.usuarioID || ''}
                variant="standard" 
                fullWidth 
                margin="normal" 
                onChange={handleChange} 
                color="info"
                inputProps={{ style: { textTransform: "lowercase" } }}
            /> */}

            <TextField
                name="permisoID"
                id="permisoID"          
                select
                label="Permiso"
                value={permisoUsuario.permisoID === 0 ? 1 : permisoUsuario.permisoID}
                
                variant="standard"
                fullWidth 
                margin="normal" 
                onChange={handleChange}
                color="info"
            >
                {tipoPermisos.map((option) => (
                    <MenuItem key={option.permisoID} value={option.permisoID}>
                    {option.permisoNombre}
                    </MenuItem>
                ))}
            </TextField>

            <FloatingActionButtons type="submit" color="success"><CheckIcon /></FloatingActionButtons>
        </Box>
    )
}