import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { ColumnaHorasDia } from './panel';
import { Container } from './styledComponents';

import { filtrarTareasPorDia } from '../../utils/tareas'


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Container
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    {children}
                </Box>
            )}
        </Container>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function PanelForMobil({ diasSemana, seleccionarSemana, semanaActualSeleccionada, tareas, editar, estadosTareas }) {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        setValue(moment().day())
    }, []);

    const [siguienteSemana, semanaActual, anteriorSemana] = seleccionarSemana;
    const [primerDiaSemana, ultimoDiaSemana] = semanaActualSeleccionada;

    return (
        <>
            <Box sx={{ marginY: 1, display: 'flex', justifyContent: 'space-between' }}>
                <IconButton size="small" onClick={siguienteSemana}>
                    <ArrowBackIosIcon />
                </IconButton>
                <Button variant="text" size="small" onClick={semanaActual}>
                    Semana Actual
                </Button>
                <IconButton size="small" onClick={anteriorSemana}>
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>
            <Box sx={{ marginY: 1, display: 'flex', justifyContent: 'center', color: theme => theme.palette.text.secondary }}>
                <Typography variant="caption">{moment(primerDiaSemana).format('DD [de] MMM')} - {moment(ultimoDiaSemana).format('DD [de] MMM')}</Typography>
            </Box>
            <Box
                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 'auto' }}
            >
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                    {diasSemana.map((item, index) => (
                        <Tab key={item.nombre} label={`${item.nombre}`} {...a11yProps(index)} />
                    ))}
                </Tabs>
                {
                    diasSemana.map((item, index) => {
                        const tareasDia = tareas.filter(tarea => filtrarTareasPorDia(tarea, item.dia));
                        return (
                            <TabPanel key={item.nombre} value={value} index={index}>
                                <Typography variant="caption" sx={{ p: 1, mb: 1, position: 'sticky', top: 0, zIndex: 999 }}>{moment(item.dia).format('ll')}</Typography>
                                <ColumnaHorasDia dia={item.dia} tareas={tareasDia} editar={(tarea) => editar(tarea)} estadosTareas={estadosTareas} />
                            </TabPanel>
                        )
                    })
                }
            </Box>
        </>
    );
}