import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { DialogActions, DialogContent } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({children, open, handleClose, titulo, actions, formularioRef}) {
    const matches = useMediaQuery('(max-width:600px)');

    return (
        <div>
            <Dialog
                fullScreen={matches}
                fullWidth
                maxWidth={"sm"}
                scroll={"paper"}
                open={open}
                // onClose={handleClose} //no cerrar con cualquier cliqueo
                TransitionComponent={Transition}
                sx={matches ? {} : { ['& div.MuiPaper-root']: { borderRadius: '10px' } }}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {titulo}
                        </Typography>
                    </Toolbar>
                </AppBar>
                
                <DialogContent 
                    dividers
                    sx={{pb: 10}}
                >
                    {children}
                </DialogContent>
                {actions && (
                    <DialogActions sx={{ justifyContent: !matches ? 'flex-end' : 'center' }}>
                        {!matches 
                            ? actions.map(({label, ...props}, index) => (
                                <Button variant="contained" size="small" key={index} {...props}>{label}</Button>
                            )) 
                            : actions.map(({startIcon, label, ...props}, index) => (
                                <IconButton key={index} size="medium" {...props}>
                                    {startIcon}
                                </IconButton>
                            ))
                        }
                    </DialogActions>
                )}
            </Dialog>
        </div>
    );
}