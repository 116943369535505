import moment from "moment";
import "moment/locale/es"

class ManagmentWeeks {
  
  callbak = null;

  constructor(year, week) {
    this.currentWeek = week ?? moment().week();
    this.currentYear = year ?? moment().year();
  }

  getRangeCurrentWeek() {
    return [
      moment().week(this.currentWeek).year(this.currentYear).startOf('week').isoWeekday(0).format(), 
      moment().week(this.currentWeek).year(this.currentYear).endOf('week').isoWeekday(6).format()
    ]
  }

  getCurrentWeek() {
    return `${this.currentYear}-W${this.currentWeek}`;
  }

  setCurrentWeek(year, week) {
    this.currentWeek = week;
    this.currentYear = year;
  }

  getNextWeek() {
    const nextWeek = moment().week(this.currentWeek).year(this.currentYear).add(1, 'weeks');
    this.currentWeek = nextWeek.week();
    this.currentYear = nextWeek.year();
  }
  
  getLastWeek() {
    const lastWeek = moment().week(this.currentWeek).year(this.currentYear).subtract(1, 'weeks');
    this.currentWeek = lastWeek.week();
    this.currentYear = lastWeek.year();
  }
}

export default ManagmentWeeks;