export const getEmpresas = async(token) => {
    const response = await fetch('api/Empresas', {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    const data = await response.json();
    return data;
}

export const getUsuarioSinEmpresa = async(usuario, token) => {
    
    try {
        const response = await fetch(`api/Usuario/GetUsuarioSinEmpresa/${usuario.sub}`, {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        });
    
        // if (response.status === 401) {
        //     await authService.signIn();
        // }
    
        return response.ok;
    }
    catch(err) {
        console.error('Error: ', err)
        return false
    }
}

export const getUsuarioEmpresa = async(usuario, token) => {
    
    try {
        const response = await fetch(`api/Usuario/GetUsuarioEmpresa/${usuario.sub}`, {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        });
    
        // if (response.status === 401) {
        //     await authService.signIn();
        // }
    
        return response.ok;
    }
    catch(err) {
        console.error('Error: ', err)
        return false
    }
}

export const getEmpresaActual = async(user, token) => {
    const response = await fetch('api/Empresas/GetEmpresaActual/' + user.sub, {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    const data = await response.json();
    return data;
}

// // export const postEmpresa = async(empresa, token) => {
// //     const response = await fetch('api/Empresas', {
// //         method: "POST",
// //         headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
// //         body: JSON.stringify(empresa)
// //     });

// //     // if (response.status === 401) {
// //     //     await authService.signIn();
// //     // }

// //     const data = await response.json();
// //     return data;
// // }

export const putEmpresa = async(empresa, user, token) => {
    empresa.nombreEmpresa = empresa.nombreEmpresa.toUpperCase();

    const response = await fetch('api/Empresas/' + empresa.empresaID + '/' + user.sub, {
        method: "PUT",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        body: JSON.stringify(empresa)
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }
    
    return response.ok;
}



export const getTipoPermisos = async(token) => {
    const response = await fetch('api/Empresas/GetTipoPermisos', {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    const data = await response.json();
    return data;
}

export const getPermisosUsuarios = async(user, token) => {
    const response = await fetch('api/Empresas/GetPermisosUsuarios/' + user.sub, {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    const data = await response.json();
    return data;
}

export const GetPermisoUsuarioActual = async(user, token) => {
    const response = await fetch('api/Empresas/GetPermisoUsuarioActual/' + user.sub, {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    if (response.status === 204) {
        return false;
    }
    
    const data = await response.json();
    return data;
}

export const postPermisoUsuario = async(user, token, permisoUsuario) => {
    const response = await fetch('api/Empresas/PostPermisoUsuario/' + user.sub, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        body: JSON.stringify(permisoUsuario)
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    const data = await response.json();
    return data;
}

export const putPermisoUsuario = async(user, token, permisoUsuario) => {
    const response = await fetch('api/Empresas/PutPermisoUsuario/' + permisoUsuario.permisoUsuarioID + '/' + user.sub, {
        method: "PUT",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        body: JSON.stringify(permisoUsuario)
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }
    
    const data = await response.json();
    return data;
}

export const activarDesactivarPermisoUsuario = async(user, token, permisoUsuario) => {
    const response = await fetch('api/Empresas/ActivarDesactivarPermisoUsuario/' + permisoUsuario.permisoUsuarioID + '/' + user.sub, {
        method: "PUT",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    const data = await response.json();
    return data;
}

export async function imagenEmpresa(user, token) {
    const response = await fetch('api/Empresas/imagen/' + user.sub, {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
    });

    try {
        if(!response.ok) {
            throw new Error
        }
        const data = await response.text();
        return data;

    } catch {
        return false
    }
}

export async function logoEmpresa(user, token) {
    const response = await fetch('api/Empresas/logo/' + user.sub, {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
    });

    try {
        if(!response.ok) {
            throw new Error
        }
        const data = await response.text();
        return data;
        
    } catch {
        return false
    }
}

export async function getUser(user, token) {
    const response = await fetch('api/Empresas/usuario/' + user.sub, {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
    });

    try {
        if(!response.ok) {
            throw new Error
        }
        const data = await response.text();
        return data;
        
    } catch {
        return false
    }
}

//activarDesactivarPermisoUsuario
export const registrarUsuarioAuth0 = async(user, token) => {
    const response = await fetch('api/Usuario/RegistrarUsuarioAuth0/' + user.email + '/' + user.sub, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
    });

    return response.ok;
}