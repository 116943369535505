import React, { useState, useRef } from "react"
import { isEqual } from 'lodash';
import { MenuItem, TextField, Box } from "@mui/material";
//Iconos
import CheckIcon from '@mui/icons-material/Check';
//Helpers
import { postLocalidad, putLocalidad } from "../../helpers/localidades";
//Custom Components
import FloatingActionButtons from "../fabButton";

import { useAuth0 } from "../../react-auth0-spa";

export default function NuevaLocalidad({ localidadSeleccionada, guardar, provincias, setCustomSnackbar }) {
    const { user, getTokenSilently } = useAuth0();
    const [ localidad, setLocalidad ] = useState(localidadSeleccionada);

    const localidadSinCambios = useRef(localidadSeleccionada);

    const handleChange = (evento) => {
        const { name, value } = evento.target;
        setLocalidad({ ...localidad, [ name ]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const token = await getTokenSilently();
        if (localidad.localidadID > 0) {
            const result = await putLocalidad(localidad, user, token);
            if(result.status != 'Error'){
                guardar(result, "PUT")
            }else{
                setCustomSnackbar({
                    mensaje: result.message,
                    tipo: result.status.toLowerCase(),
                    direccion: "right",
                    open: true,
                    handleClose: () => setCustomSnackbar(prevState => ({...prevState, open: false}))
                });

            }
            
        }
        else {
            const result = await postLocalidad(localidad, user, token);
            if(result.status != 'Error'){
                guardar(result, "POST")
            }else{
                setCustomSnackbar({
                    mensaje: result.message,
                    tipo: result.status.toLowerCase(),
                    direccion: "right",
                    open: true,
                    handleClose: () => setCustomSnackbar(prevState => ({...prevState, open: false}))
                });
            }
            
        }
    }

    const BotonGuardarComponent = () => {
        if(isEqual(localidad, localidadSinCambios.current)) {
            return null
        }

        return (
            <FloatingActionButtons type="submit" color="success">
                <CheckIcon /> 
            </FloatingActionButtons>
        )
    }

    return (
        <Box
            component="form"
            sx={{ display: 'flex', flexWrap: 'wrap' }}
            autoComplete="off"
            onSubmit={handleSubmit}
        >
            <TextField
                required
                autoFocus
                name="nombre"
                label="Nombre"
                value={localidad.nombre}
                variant="standard"
                fullWidth
                margin="normal"
                onChange={handleChange}
                color="info"
                inputProps={{ style: { textTransform: "uppercase" } }}
            />
            <TextField
                name="codigoPostal"
                label="Código postal"
                value={localidad.codigoPostal}
                variant="standard"
                fullWidth
                margin="normal"
                onChange={handleChange}
                color="info"
                inputProps={{ style: { textTransform: "uppercase" }, maxLength: 4 }}
            />
            <div className="small">
            <a href="https://www.correoargentino.com.ar/formularios/cpa" target= "_blank" >¿No conoce su código postal?</a>
            </div>

            <TextField
                name="provinciaID"
                required
                id="provinciaID"
                select
                label="Provincia"
                defaultValue=""
                value={localidad.provinciaID === 0 ? "" : localidad.provinciaID}
                variant="standard"
                fullWidth
                margin="normal"
                onChange={handleChange}
                color="info"
            >
                {provincias.map((option) => (
                    <MenuItem key={option.provinciaID} value={option.provinciaID}>
                        {option.provinciaNombre}
                    </MenuItem>
                ))}
            </TextField>
        

           <BotonGuardarComponent/>
            
        </Box>
        
    )
}
