import React, { useState, useRef } from "react"
import { isEqual } from 'lodash';
import { MenuItem, TextField, Box } from "@mui/material";
//Iconos
import CheckIcon from '@mui/icons-material/Check';
//Helpers
import { postPersona, putPersona } from "../../helpers/persona";
//Custom Components
import FloatingActionButtons from "../fabButton";

import { useAuth0 } from "../../react-auth0-spa";

export default function Nuevapersona({ personaSeleccionada, guardar, localidades, setCustomSnackbar }) {
    const { user, getTokenSilently } = useAuth0();
    const [ persona, setPersona ] = useState(personaSeleccionada);

    const personaSinCambios = useRef(personaSeleccionada);

    const handleChange = (evento) => {
        const { name, value } = evento.target;
        setPersona({ ...persona, [ name ]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const token = await getTokenSilently();
        if (persona.personaID > 0) {
            const result = await putPersona(persona, user, token);
            if(result.status != 'Error'){
                guardar(result, "PUT")
            }else{
                setCustomSnackbar({
                    mensaje: result.message,
                    tipo: result.status.toLowerCase(),
                    direccion: "right",
                    open: true,
                    handleClose: () => setCustomSnackbar(prevState => ({...prevState, open: false}))
                });
            }
        }
        else {
            const result = await postPersona(persona, user, token);
            if(result.status != 'Error'){
                guardar(result, "POST")
            }else{
                setCustomSnackbar({
                    mensaje: result.message,
                    tipo: result.status.toLowerCase(),
                    direccion: "right",
                    open: true,
                    handleClose: () => setCustomSnackbar(prevState => ({...prevState, open: false}))
                });
            }
        }
    }

    const BotonGuardarComponent = () => {
        if(isEqual(persona, personaSinCambios.current)) {
            return null
        }

        return (
            <FloatingActionButtons type="submit" color="success">
                <CheckIcon />
            </FloatingActionButtons>
        )
    }

    return (
        <Box
            component="form"
            sx={{ display: 'flex', flexWrap: 'wrap' }}
            autoComplete="off"
            onSubmit={handleSubmit}
        >
            <TextField
                required
                autoFocus
                name="nombreCompleto"
                label="Nombre"
                value={persona.nombreCompleto}
                variant="standard"
                fullWidth
                margin="normal"
                onChange={handleChange}
                color="info"
                inputProps={{ style: { textTransform: "uppercase" } }}
            />
            <TextField
                name="nombreFantasia"
                label="Nombre de Fantasía"
                value={persona.nombreFantasia}
                variant="standard"
                fullWidth
                margin="normal"
                onChange={handleChange}
                color="info"
                inputProps={{ style: { textTransform: "uppercase" } }}
            />
            <TextField
                required
                type="text"
                name="dni"
                label="DNI"
                value={persona.dni}
                variant="standard"
                fullWidth
                margin="normal"
                onChange={handleChange}
                color="info"
                inputProps={{ style: { textTransform: "uppercase" }, maxLength: 8, }}
                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
            />
            <TextField
                type="text"
                name="celular"
                label="Celular"
                value={persona.celular}
                variant="standard"
                fullWidth
                margin="normal"
                onChange={handleChange}
                color="info"
                inputProps={{ style: { textTransform: "uppercase" }, maxLength: 15 }}
                onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
            />
            <TextField
                name="direccion"
                label="Dirección"
                value={persona.direccion}
                variant="standard"
                fullWidth
                margin="normal"
                onChange={handleChange}
                color="info"
                inputProps={{ style: { textTransform: "uppercase" } }}
            />
              <TextField
                name="correoElectronico"
                label="Correo electronico"
                value={persona.correoElectronico}
                variant="standard"
                fullWidth
                margin="normal"
                onChange={handleChange}
                color="info"
                inputProps={{ style: { textTransform: "uppercase" } }}
            />

            <TextField
                name="localidadID"
                required
                id="localidadID"
                select
                label="Localidad"
                defaultValue=""
                value={persona.localidadID === 0 ? "" : persona.localidadID}
                variant="standard"
                fullWidth
                margin="normal"
                onChange={handleChange}
                color="info"
            >
                {localidades.map((option) => (
                    <MenuItem key={option.localidadID} value={option.localidadID}>
                        {option.nombre}({option.codigoPostal})/{option.provincia.provinciaNombre}
                    </MenuItem>
                ))}
            </TextField>

            <BotonGuardarComponent />
        </Box>
    )
}
