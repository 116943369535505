import React, { memo, useState, useEffect, useCallback } from 'react'
import moment from 'moment'
import { isEqual } from 'lodash'
import { Grid, Box, Typography, Chip, Button, MenuItem, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery';
//Iconos
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FilterListIcon from '@mui/icons-material/FilterList';
import TodayIcon from '@mui/icons-material/Today';
//Utils
import { esDiaActual, esDiaAnterior, esDiaPosterior, esPrimerDiaSemana, horasDelDia, diasPorSemana, estaDentroHora } from '../../utils/dias'
import { filtrarTareasPorDia } from '../../utils/tareas'
//Custom Components
import CustomAutocomplete from '../customAutocomplete';
import FloatingActionButtons from "../fabButton";
import { Container, ContainerHeader, Header, ItemTarea, Columna, StateTaskContainer } from './styledComponents'
import PanelForMobil from './panel-movil';

import Weeks from '../../utils/semanas';

const isTaskRepeat = (fechaTarea, hora, dia) => moment(fechaTarea).hours() !== parseInt(hora) || !moment(fechaTarea).isSame(dia, 'day');

const horas = horasDelDia();
export const ColumnaHorasDia = memo(({ dia, tareas, editar, estadosTareas }) => {
    const classes = esDiaActual(dia) ? 'esDiaActual'
        : esDiaPosterior(dia) ? 'esDiaPosterior'
            : esDiaAnterior(dia) ? 'esDiaAnterior'
                : esPrimerDiaSemana(dia) ? 'esPrimerDiaSemana'
                    : '';


    return (
        <Columna as="section" className={classes}>
            {horas.map(hora => {
                const tareasDentroHora = tareas.filter(tarea => estaDentroHora(tarea.fechaTarea, tarea.fechaTareaFin, hora, dia));
                return (
                    <ItemTarea key={hora} hasElements={tareasDentroHora.length}>
                        <small className="hour">{hora}</small>
                        <div className="task_container">
                            {tareasDentroHora.map((tarea, index) => {
                                const tareaRepetida = isTaskRepeat(tarea.fechaTarea, hora, dia);
                                const estadoTarea = estadosTareas.find(estado => estado.estadoTareaID === tarea.estadoTareaID);

                                return (
                                    <Chip
                                        key={index}
                                        sx={{ borderRadius: 2 }}
                                        size="small"
                                        label={tarea.nombre}
                                        variant={tareaRepetida ? "outlined" : "contained"}
                                        onClick={tareaRepetida ? null : () => editar(tarea)}
                                        icon={estadoTarea?.icon}
                                        color={estadoTarea?.color}
                                    />
                                )
                            })}
                        </div>
                    </ItemTarea>
                )
            })}
        </Columna>
    )
}, isEqual)

const weeks = new Weeks();

export default function Panel({ lista, esUsuarioTareas, estadosTareas, servicios, tiposTareas, usuariosEmpresa, actions }) {
    const [state, setState] = useState({ diasSemana: [], semanaNumero: 0, semanaActualSeleccionada: [], semanaAnioString: '', loading: false, showFilters: false });
    const [tareas, setTareas] = useState(lista);

    const [filtros, setFiltros] = useState({ usuarios: [], estados: [1, 2, 3], servicioId: null, tipoTareaId: null });

    const theme = useTheme();
    const matches_sm = useMediaQuery(theme.breakpoints.up('sm'));
    const matches_md = useMediaQuery(theme.breakpoints.up('md'));

    const { editar, filtrar } = actions;

    useEffect(() => {
        const semanaActualSeleccionada = weeks.getRangeCurrentWeek();
        const semanaNumero = weeks.currentWeek;
        const semanaAnioString = weeks.getCurrentWeek();
        const diasSemana = diasPorSemana();

        filtrar(0, ...semanaActualSeleccionada);
        setState(prevState => ({ ...prevState, diasSemana, semanaNumero, semanaActualSeleccionada, semanaAnioString }));
    }, []);

    useEffect(() => {
        const filtroPorEstado = lista.filter(tarea => filtros.estados.includes(tarea.estadoTareaID));
        const filtroPorServicio = filtros.servicioId ? filtroPorEstado.filter(tarea => tarea.servicioID === filtros.servicioId) : [...filtroPorEstado];
        const filtroPorTipoTarea = filtros.tipoTareaId ? filtroPorServicio.filter(tarea => tarea.tipoTareaID === filtros.tipoTareaId) : [...filtroPorServicio];
        if (filtros.usuarios.length > 0) {
            const filtroPorUsuario = filtroPorTipoTarea.filter(tarea => tarea.listadoUsuarios.some(u => filtros.usuarios.find(x => x.usuarioID === u.usuarioID)));
            setTareas(filtroPorUsuario)
            return
        }
        setTareas(filtroPorTipoTarea)

    }, [filtros, lista]);

    const handleToggledFilters = () => setState(prevState => ({ ...prevState, showFilters: !prevState.showFilters }));

    const changeWeek = useCallback(() => {
        setState(prevState => ({ ...prevState, loading: true }))

        const semanaNumero = weeks.currentWeek;
        const semanaActualSeleccionada = weeks.getRangeCurrentWeek();
        const semanaAnioString = weeks.getCurrentWeek();
        const diasSemana = diasPorSemana(semanaNumero);

        setState(prevState => ({ ...prevState, diasSemana, semanaNumero, semanaActualSeleccionada, semanaAnioString, loading: false }))
        filtrar(0, ...semanaActualSeleccionada);
    }, [weeks.currentWeek])

    const handleCurrentWeek = () => {
        weeks.setCurrentWeek(moment().year(), moment().week());
        changeWeek();
    }

    const handleNextWeek = () => {
        weeks.getNextWeek();
        changeWeek();
    }

    const handleLastWeek = () => {
        weeks.getLastWeek();
        changeWeek();
    }

    const handleChangeWeek = (e) => {
        const semana = moment(e.target.value.replace('-', ''));
        console.log('lalalala', semana.week(), e.target.value)
        weeks.setCurrentWeek(semana.year(), semana.week());
        changeWeek();
    }

    const handleFiltrarPorUsuario = (e, usuarios) => {
        setFiltros(prevState => ({ ...prevState, usuarios }))
    }

    const handleFiltrarPorEstado = (estadoId, add = false) => {
        if (add) {
            setFiltros(prevState => ({ ...prevState, estados: [...prevState.estados, estadoId] }))
            return
        }

        setFiltros(prevState => ({ ...prevState, estados: [...prevState.estados.filter(id => id !== estadoId)] }))
    }

    const handleChangeServicesAndTypeTask = (event) => {
        const { name, value } = event.target;
        setFiltros(prevState => ({ ...prevState, [name]: value }))
    }

    return (
        <>
            {
                !matches_md ? (
                    <>
                        <StateTaskContainer as="section">
                            {estadosTareas.map((estado, index) => {
                                const { label, icon, color } = estado;
                                return (
                                    <Chip
                                        key={index}
                                        size="small"
                                        variant={filtros.estados.some(id => id === estado.estadoTareaID) ? 'contained' : 'outlined'}
                                        onClick={!esUsuarioTareas ? () => handleFiltrarPorEstado(estado.estadoTareaID, !filtros.estados.some(id => id === estado.estadoTareaID)) : null}
                                        sx={{ ['&:not(:last-child)']: { mr: 1 } }}
                                        {...{ label, icon, color }}
                                    />
                                )
                            })}
                        </StateTaskContainer>
                        
                        
                        <PanelForMobil
                            diasSemana={state.diasSemana}
                            seleccionarSemana={[handleLastWeek, handleCurrentWeek, handleNextWeek]}
                            semanaActualSeleccionada={state.semanaActualSeleccionada}
                            tareas={tareas}
                            editar={editar}
                            estadosTareas={estadosTareas}
                        />
                    </>
                ) : (
                    <>
                        <Box as="section" sx={{ display: 'flex', alignItems: 'flex-end', gap: 2 }}>
                            {!esUsuarioTareas && state.showFilters && (
                                <>
                                    <Box sx={{ flex: 1 / 4 }}>
                                        <TextField
                                            name="servicioId"
                                            id="servicioId"
                                            select
                                            label="Servicio"
                                            value={filtros.servicioId || ""}
                                            variant="standard"
                                            fullWidth
                                            margin="dense"
                                            onChange={handleChangeServicesAndTypeTask}
                                            color="info"
                                            sx={{ mb: 0 }}
                                        >
                                            <MenuItem value="">
                                                Todos
                                            </MenuItem>
                                            {servicios.map((option) => (
                                                <MenuItem key={option.servicioID} value={option.servicioID}>
                                                    {option.titulo}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Box>
                                    <Box sx={{ flex: 1 / 4 }}>
                                        <TextField
                                            name="tipoTareaId"
                                            id="tipoTareaId"
                                            select
                                            label="Tipo de Tarea"
                                            value={filtros.tipoTareaId || ""}
                                            variant="standard"
                                            fullWidth
                                            margin="dense"
                                            onChange={handleChangeServicesAndTypeTask}
                                            color="info"
                                            sx={{ mb: 0 }}
                                        >
                                            <MenuItem value="">
                                                Todos
                                            </MenuItem>
                                            {tiposTareas.map((option) => (
                                                <MenuItem key={option.tipoTareaID} value={option.tipoTareaID}>
                                                    {option.tipoTareaNombre}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Box>
                                    <Box sx={{ flex: 2 / 4 }}>
                                        <CustomAutocomplete
                                            label="Buscar Usuario"
                                            elements={usuariosEmpresa}
                                            onChange={handleFiltrarPorUsuario}
                                            elementsActive={filtros.usuarios}
                                            size="small"
                                            sx={{ m: 0 }}
                                        />
                                    </Box>
                                </>
                            )}
                        </Box>
                        
                        
                        <Box as="section" sx={{ marginY: 1, display: 'flex', justifyContent: 'center', gap: 1 }}>
                            {
                                [
                                    { variant: 'outlined', startIcon: <ArrowBackIosIcon />, onClick: handleLastWeek, label: 'Anterior Semana' },
                                    { variant: state.semanaNumero === moment().week() ? "contained" : "outlined", endIcon: <TodayIcon />, onClick: handleCurrentWeek, label: 'Semana Actual' },
                                    { variant: 'outlined', endIcon: <ArrowForwardIosIcon />, onClick: handleNextWeek, label: 'Próxima Semana' },
                                ].map(({ label, ...props }, index) => (
                                    <Button key={index} disabled={state.loading} size="small" {...props}>{label}</Button>
                                ))
                            }
                        </Box>
                        
                        
                        <Container>
                            <Grid as={ContainerHeader} container columns={7}>
                                {
                                    state.diasSemana.map((item, index) => (
                                        <Grid key={index} as={Header} item xs={1} className={esDiaActual(item.dia) ? 'currentDay' : ''}>
                                            <Typography variant="body1">{item.nombre}</Typography>
                                            <Typography variant="caption">{item.fecha}</Typography>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                            <Grid as="section" container columns={7}>
                                {
                                    state.diasSemana.map(item => {
                                        const tareasDia = tareas.filter(tarea => filtrarTareasPorDia(tarea, item.dia));
                                        return (
                                            <Grid key={item.nombre} item xs={1}>
                                                <ColumnaHorasDia dia={item.dia} tareas={tareasDia} editar={(tarea) => editar(tarea)} estadosTareas={estadosTareas} />
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Container>
                        
                        
                        <StateTaskContainer as="section">
                            {estadosTareas.map((estado, index) => {
                                const { label, icon, color } = estado;
                                return (
                                    <Chip
                                        key={index}
                                        size="small"
                                        variant={filtros.estados.some(id => id === estado.estadoTareaID) ? 'contained' : 'outlined'}
                                        onClick={!esUsuarioTareas ? () => handleFiltrarPorEstado(estado.estadoTareaID, !filtros.estados.some(id => id === estado.estadoTareaID)) : null}
                                        sx={{ ['&:not(:last-child)']: { mr: 1 } }}
                                        {...{ label, icon, color }}
                                    />
                                )
                            })}
                        </StateTaskContainer>
                        
                        
                        {!esUsuarioTareas && (
                            <FloatingActionButtons bottom={85} onClick={handleToggledFilters} color={state.showFilters ? "primary" : "default"} position="fixed">
                                <FilterListIcon />
                            </FloatingActionButtons>
                        )}
                    </>
                )
            }
        </>
    )
}