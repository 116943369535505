export const getTipoTareas = async(user, token) => {
    const response = await fetch('api/TiposTareas/' + user.sub, {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    const data = await response.json();
    return data;
}


export const postTipoTarea = async(tipoTarea, user, token) => {
    tipoTarea.tipoTareaNombre = tipoTarea.tipoTareaNombre.toUpperCase();
    
    const response = await fetch('api/TiposTareas/' + user.sub, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        body: JSON.stringify(tipoTarea)
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    const data = await response.json();
    return data;
}


export const putTipoTarea = async(tipoTarea, user, token) => {
    tipoTarea.tipoTareaNombre = tipoTarea.tipoTareaNombre.toUpperCase();

    const response = await fetch('api/TiposTareas/' + tipoTarea.tipoTareaID + '/' + user.sub, {
        method: "PUT",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        body: JSON.stringify(tipoTarea)
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    const data = await response.json();
    return data;
}


export const deleteTipoTarea = async(tipoTarea, user, token) => {
    const response = await fetch('api/TiposTareas/' + tipoTarea.tipoTareaID + '/' + user.sub, {
        method: "DELETE",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    const data = await response.json();
    return data;
}