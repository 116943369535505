import moment from 'moment'

export const getServicios = async(user, token) => {
    const response = await fetch('api/Servicios/' + user.sub, {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    const data = await response.json();
    return data;
}


export const postServicio = async(servicio, user, token) => {
    const fechaServicio = moment(servicio.fechaServicio).toDate();
    servicio.fechaServicio = fechaServicio;
    servicio.titulo = servicio.titulo.toUpperCase();

    const response = await fetch('api/Servicios/' + user.sub, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        body: JSON.stringify(servicio)
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    const data = await response.json();
    return data;
}


export const putServicio = async(servicio, user, token) => {
    const fechaServicio = moment(servicio.fechaServicio).toDate();
    servicio.fechaServicio = fechaServicio;
    servicio.titulo = servicio.titulo.toUpperCase();

    const response = await fetch('api/Servicios/' + servicio.servicioID + '/' + user.sub, {
        method: "PUT",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        body: JSON.stringify(servicio)
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    const data = await response.json();
    return data;
}


export const deleteServicio = async(servicio, user, token) => {
    const response = await fetch('api/Servicios/' + servicio.servicioID + '/' + user.sub, {
        method: "DELETE",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }
    
    const data = await response.json();
    return data;
}


export const checkServicio = async(servicio, user, token) => {
    const response = await fetch('api/Servicios/CheckServicio/' + servicio.servicioID + '/' + user.sub, {
        method: "PUT",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    const data = await response.json();
    return data;
}

export const activarNotificacion = async(servicio, user, activar = true, token) => {
    const response = await fetch(`api/Servicios/ActivarNotificaciones/${servicio.servicioID}?sub=${user.sub}?activar=${Number(activar)}`, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    const data = await response.json();
    return data;
}