import React, { useState, useEffect } from "react"

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Paper } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';

import { useHistory } from "react-router-dom"

import { getEmpresas, getEmpresaActual, postEmpresa, putEmpresa } from "../../helpers/empresa";
import FloatingActionButtons from "../fabButton";


import { useAuth0 } from "../../react-auth0-spa";


export default function NuevaEmpresa() {
    const { user, getTokenSilently } = useAuth0();
    const navegar = useHistory();

    //CODIGO QUE IRIA EN EL REGISTRAR
    const initialState = {
        empresaID: 0,
        nombreEmpresa: '',
        empresaIDGestion: 0,
        urlGestion: '',
        token: '',
        direccion: '',
        telefono: '',
        imagen: null,
        logo: null,
        imagenString: '',
        logoString: '',
        personaID: 0,
        usuarioID: ''
    }

    const [empresa, setEmpresa] = useState(initialState)
    

    const obtenerEmpresa = async () => {
        const token = await getTokenSilently();
        getEmpresaActual(user, token)
            .then(respuestaEmpresa => {
                if(respuestaEmpresa.empresaID > 0){
                    setEmpresa(respuestaEmpresa);
                }
            })
    };

    useEffect(() => {
        obtenerEmpresa();
    }, [])

   
    
    const handleChange = (evento) => {
        const {name, value} = evento.target;
        setEmpresa({...empresa, [name]: value});
    }

    const guardar = async () => {
        const token = await getTokenSilently();

        // if(empresa.empresaID === 0){
        //     const result = await postEmpresa(empresa, token);
        // }
        // else{
        const result = await putEmpresa(empresa, user, token);
        // }
      
        navegar.push("/");
    }

    var onChangeFileButton = (e) => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          if (fileReader.readyState === 2) {
            setEmpresa({...empresa, imagenString: fileReader.result, imagen:null});
          }
        };
        fileReader.readAsDataURL(e.target.files[0]);
    }

    var onChangeIcon = (e) => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          if (fileReader.readyState === 2) {
            setEmpresa({...empresa, logoString: fileReader.result, logo: null });
          }
        };
        fileReader.readAsDataURL(e.target.files[0]);
    }

    const handleDeleteImg = () => setEmpresa({ ...empresa, imagenString: '', imagen: null });
    const handleDeleteLogo = () => setEmpresa({ ...empresa, logoString: '', logo: null });
        
    return (
        <Box
            sx={{ display: 'flex', flexWrap: 'wrap', maxWidth: 500, mx: 'auto' }}
            noValidate
        >
            
            <TextField 
                name="nombreEmpresa"
                label="Razón Social *"
                value={empresa.nombreEmpresa}
                variant="standard" 
                fullWidth 
                margin="normal" 
                onChange={handleChange} 
                color="info"
                autoComplete="off"
                inputProps={{ style: { textTransform: "uppercase" } }}
            />
            
            <TextField 
                name="direccion"
                label="Dirección *"
                value={empresa.direccion}
                variant="standard" 
                fullWidth 
                margin="normal" 
                onChange={handleChange} 
                color="info"
                autoComplete="off"
                inputProps={{ style: { textTransform: "uppercase" } }}
            />
            
            <TextField 
                name="telefono" 
                label="Teléfono *" 
                value={empresa.telefono} 
                variant="standard" 
                fullWidth 
                margin="normal" 
                onChange={handleChange} 
                color="info"
                autoComplete="off"
            />

            <Box sx={{ display: 'block', width: '100%' }}>
                <Box>
                    <Box as="label">
                        <input hidden accept="image/*" id="contained-button-file" multiple type="file" onChange={onChangeFileButton} />
                        <Button variant="contained" component="span" size="small">
                            {!empresa.imagenString ? "Seleccionar Imagen" : "Cambiar Imagen"}
                        </Button>
                    </Box>
                    {
                        empresa.imagenString && (
                            <Button variant="contained" color="error" size="small" sx={{ ml: 1 }} onClick={handleDeleteImg}>
                                Eliminar
                            </Button>
                        )
                    }
                </Box> 

                {
                    empresa.imagenString && 
                    <Paper square sx={{ borderRadius: 2, overflow: 'hidden' }}>
                        <Box component="img" sx={{ width:'100%', objectFit: 'cover' }} src={empresa.imagenString}/>
                    </Paper>
                }
            </Box>

            <Box as="hr" sx={{ width: '100%', backgroundColor: 'text.secondary', m: 0, mb: 1 }} />

            <Box sx={{ display: 'block', width: '100%' }}>
                <Box>
                    <Box as="label">
                        <input hidden accept=".png" type="file" onChange={onChangeIcon} />
                        <Button variant="contained" component="span" size="small">
                            {!empresa.logoString ? "Seleccionar Logo" : "Cambiar Logo"}
                        </Button>
                    </Box>
                    {
                        empresa.logoString && (
                            <Button variant="contained" color="error" size="small" sx={{ ml: 1 }} onClick={handleDeleteLogo}>
                                Eliminar
                            </Button>
                        )
                    }
                </Box> 

                {
                    empresa.logoString && 
                    <Paper sx={{ borderRadius: 2, overflow: 'hidden', width: 'fit-content', height: 100, }}>
                        <Box component="img" sx={{ height: '100%', objectFit: 'cover' }} src={empresa.logoString}/>
                    </Paper>
                }
            </Box>
          

            <FloatingActionButtons onClick={guardar} color="success"><CheckIcon /></FloatingActionButtons>
        </Box>
    )
}