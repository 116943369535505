import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

const actions = {
    Copy: <FileCopyIcon />,
    Save: <SaveIcon />,
    Print: <PrintIcon />,
    Share: <ShareIcon />,
    Edit: <EditIcon />,
    Add: <AddIcon />,
};

export default function SpeedDialButton({options, position, disabled}) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const acciones = options.map((option) => 
        ({icon: actions[option[0]], name: option[1], onClick: option[2]})
    );

    const fabStyle = {
        position: position || 'absolute',
        bottom: 16,
        right: 16,
    };

    return (
        <Box>
            <Backdrop open={open} />
            <SpeedDial
                ariaLabel="add"
                sx={fabStyle}
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                hidden={disabled}
            >
                {acciones.map((accion) => (
                    <SpeedDialAction
                        key={accion.name}
                        icon={accion.icon}
                        tooltipTitle={accion.name}
                        tooltipOpen
                        onClick={() => {
                            handleClose();
                            accion.onClick();
                        }}
                    />
                ))}
            </SpeedDial>
        </Box>
    );
}