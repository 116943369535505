import React, { useState } from 'react';
import { Tab, Tabs, Box } from '@mui/material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`custom-tabpanel-${index}`}
      aria-labelledby={`custom-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>{children}</Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `custom-tab-${index}`,
    'aria-controls': `custom-tabpanel-${index}`,
  };
}

export default function CustomTabs({ tabs, content }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="custom tab">
          {tabs.map((tab, index) => (
            <Tab label={tab} {...a11yProps(index)} key={index} />
          ))}
        </Tabs>
      </Box>
      {content.map((item, index) => (
        <TabPanel value={value} index={index} key={index}>
          {item}
        </TabPanel>
      ))}
    </Box>
  );
}