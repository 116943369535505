import React, { useEffect, useState } from 'react'
import { isEqual } from 'lodash'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'

import { useAuth0 } from "../react-auth0-spa";

import { requestNotificationAccess, getSubscription, getSubscriptionServer, subscribe, unsubscribe } from '../helpers/notificaciones'

const NOTIFICATION_PERMISSION = {
    GRANTED: 'granted',
    BLOCKED: 'blocked',
    DENIED: 'denied',
    DEFAULT: 'default'
}

const areSubscriptionEquals = (localSubs, serverSubs) => {
    const serverSubscription = { ...serverSubs, p256dh: serverSubs.p256DH };
    delete serverSubscription.p256DH;

    return isEqual(serverSubscription, localSubs)
}

export default function ActivarNotificacion() {
    const { user, getTokenSilently } = useAuth0();
    const [state, setState] = useState({ permiso: NOTIFICATION_PERMISSION.DEFAULT, loading: true });
    const [subscription, setSubscription] = useState({ applicationServerKey: '', hasSubscription: false });
    const [alert, setAlert] = useState(null);
    const [buttonActivate, setButtonActivate] = useState(null);

    useEffect(() => {
        // const FetchGetSubscription = async () => {
        //     const sub = await getSubscriptionServer();
        //     const { applicationServerKey, subscripcionNotificacion } = sub;
        //     setSubscription(prevState => ({...prevState, applicationServerKey}));

        //     try {
        //         const localSubscription = await getSubscription(applicationServerKey);
        //         console.log({subscripcionNotificacion, localSubscription})

        //         if(!localSubscription.ok || !subscripcionNotificacion) return

        //         if(areSubscriptionEquals(localSubscription.values, subscripcionNotificacion)) {
        //             setSubscription(prevState => ({...prevState, hasSubscription: true}));
        //         } else {
        //             const unsubscribeSuccessfully = await unsubscribe();
        //             if(unsubscribeSuccessfully) {
        //                 const newSubscription = await getSubscription(applicationServerKey);
        //                 if(newSubscription.ok) {
        //                     const subscribeSuccessfully = await subscribe(newSubscription.values);
        //                     if(subscribeSuccessfully) {
        //                         setSubscription(prevState => ({ ...prevState, hasSubscription: true }));
        //                     }
        //                 }
        //             }
        //         }
        //     } catch(e) {
        //         console.error('Error in ActivarNotificacion - useEffect - FetchGetSubscription:', e);
        //     }
        // }

        FetchGetSubscription();

        switch (Notification.permission) {
            case NOTIFICATION_PERMISSION.GRANTED:
                setState({ permiso: NOTIFICATION_PERMISSION.GRANTED, loading: false })
                break;
            case NOTIFICATION_PERMISSION.BLOCKED:
            case NOTIFICATION_PERMISSION.DENIED:
                setState({ permiso: NOTIFICATION_PERMISSION.BLOCKED, loading: false })
                break;
            default:
                setState({ permiso: NOTIFICATION_PERMISSION.DEFAULT, loading: false })
        }

    }, [])

    const FetchGetSubscription = async () => {
        const token = await getTokenSilently();
        const sub = await getSubscriptionServer(user, token);
        const { applicationServerKey, subscripcionNotificacion } = sub;
        setSubscription(prevState => ({ ...prevState, applicationServerKey }));

        try {
            const localSubscription = await getSubscription(applicationServerKey);

            if (!localSubscription.ok || !subscripcionNotificacion) return

            if (areSubscriptionEquals(localSubscription.values, subscripcionNotificacion)) {
                setSubscription(prevState => ({ ...prevState, hasSubscription: true }));
            } else {
                const unsubscribeSuccessfully = await unsubscribe(user, token);
                
                if (unsubscribeSuccessfully) {
                    const newSubscription = await getSubscription(applicationServerKey);
                    
                    if (newSubscription.ok) {
                        const subscribeSuccessfully = await subscribe(user, newSubscription.values, token);
                        if (subscribeSuccessfully) {
                            setSubscription(prevState => ({ ...prevState, hasSubscription: true }));
                        }
                    }
                }
            }
        } catch (e) {
            console.error('Error in ActivarNotificacion - useEffect - FetchGetSubscription:', e);
        }
    }



    const handlePermisosNotificaciones = async () => {
        setState(prevState => ({ ...prevState, loading: true }));

        const permiso = await requestNotificationAccess();
        setState({ permiso, loading: false });
    }

    const handleSubscripcionNotificaciones = async () => {
        //debugger
        setState(prevState => ({ ...prevState, loading: true }));
        const token = await getTokenSilently();
        const { applicationServerKey } = subscription;
        const { ok, values } = await getSubscription(applicationServerKey);
        if (ok) {
            const subscribeSuccessfully = await subscribe(user, values, token);
            if (subscribeSuccessfully) {
                setSubscription(prevState => ({ ...prevState, hasSubscription: true }));
            }
        }

        setState(prevState => ({ ...prevState, loading: false }));
    }

    const handleDesubscripcionNotificaciones = async () => {
        setState(prevState => ({ ...prevState, loading: true }));

        const unsubscribeSuccessfully = await unsubscribe();
        if (unsubscribeSuccessfully) {
            setSubscription(prevState => ({ ...prevState, hasSubscription: false }));
        }

        setState(prevState => ({ ...prevState, loading: false }));
    }

    useEffect(() => {
        switch (state.permiso) {
            case NOTIFICATION_PERMISSION.BLOCKED:
            case NOTIFICATION_PERMISSION.DENIED:
                setAlert({
                    severity: 'error',
                    message: 'Notificaciones bloquedas en este Dispositivo.'
                });
                setButtonActivate(null)
                break
            case NOTIFICATION_PERMISSION.DEFAULT:
                setAlert({
                    severity: 'info',
                    message: 'Habilitar los permisos para recibir notificaciones.'
                });
                setButtonActivate({
                    variant: 'contained',
                    startIcon: <NotificationsOffIcon />,
                    children: 'Habilitar Notificaciones',
                    onClick: handlePermisosNotificaciones
                })
                break;
            case NOTIFICATION_PERMISSION.GRANTED:
                if (!subscription.hasSubscription) {
                    setAlert({
                        severity: 'info',
                        message: 'Activar notificaciones.'
                    });
                    setButtonActivate({
                        variant: 'contained',
                        startIcon: <NotificationsNoneIcon />,
                        children: 'Recibir Notificaciones',
                        onClick: handleSubscripcionNotificaciones
                    })
                } else {
                    setAlert({
                        severity: 'success',
                        message: 'Notificaciones Activadas.'
                    });
                    setButtonActivate({
                        variant: 'outlined',
                        startIcon: <NotificationsActiveIcon />,
                        children: 'Dejar de Recibir Notificaciones',
                        onClick: handleDesubscripcionNotificaciones
                    })
                }
                break
            default:
                setAlert(null)
                setButtonActivate(null)
        }

    }, [state.permiso, subscription.hasSubscription])

    return (
        <Box>
            {alert && <Alert severity={alert.severity} sx={{ mb: 1 }}>{alert.message}</Alert>}
            {buttonActivate && <Button size='small' {...buttonActivate} disabled={state.loading} />}
        </Box>
    )
}