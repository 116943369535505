import React, {useState} from 'react';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Tooltip from "@mui/material/Tooltip";

import {useTheme, useMediaQuery} from '@mui/material'

import { useAuth0 } from "../../react-auth0-spa";

export default function BotonesAccionesTabla({ tarea, disabled, notificar, eliminar, finalizar }) {
    const { user, getTokenSilently } = useAuth0();
    const [ loading, setLoading ] = useState(false);
    const theme = useTheme();
    const match_md = useMediaQuery(theme.breakpoints.up('md'));

    if(tarea.estadoTareaID === 3) {
        return null
    }

    const BotonNotificaciones = async ({ config }) => {
        const token = await getTokenSilently();
        debugger
        if(disabled) {
            return null
        }

        if(tarea.notificar) {
            return (
                <Tooltip title="Dejar de Notificar Cambios" arrow disableInteractive>
                    <span>
                        <IconButton
                            sx={(theme) => ({
                                bgcolor: theme.palette.primary.mainOpacity,
                                color: theme.palette.primary.main,
                                '&:disabled': {
                                    bgcolor: theme.palette.primary.mainOpacity,
                                    color: theme.palette.primary.main,
                                    opacity: .55
                                }
                            })}
                            onClick={async () => {
                                setLoading(true);
                                await notificar(user, token, tarea, false)
                                setLoading(false)
                            }}
                            aria-label="notify"
                            disabled={loading || tarea.bloquearNotificar}
                            size={match_md ? "medium" : "small"}
                        >
                            <NotificationsActiveIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            )
        }
        

        return (
            <Tooltip title="Notificar Cambios" arrow disableInteractive>
                <span>
                    <IconButton 
                        onClick={async () => {
                            setLoading(true);
                            await notificar(user, token, tarea)
                            setLoading(false)
                        }} 
                        aria-label="notify" 
                        disabled={loading}
                        size={match_md ? "medium" : "small"}
                    >
                        <NotificationsNoneIcon />
                    </IconButton>
                </span>
            </Tooltip>
        )
    }

    return (
        <div style={{display: 'flex', gap: 5}}>
            {/* <BotonNotificaciones /> */}
            <Tooltip title="Eliminar" arrow disableInteractive>
                <span>
                    <IconButton 
                        onClick={() => eliminar(tarea)} 
                        aria-label="delete" 
                        disabled={disabled || loading || tarea.estadoTareaID === 3}
                        size={match_md ? "medium" : "small"}
                    >
                        <DeleteIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title={tarea.estadoTareaID === 1 ? "Comenzar" : "Finalizar"} arrow disableInteractive>
                <span>
                    <IconButton 
                        onClick={() => finalizar(tarea)} 
                        aria-label={tarea.estadoTareaID === 1 ? "start" : "finish"} 
                        disabled={loading || tarea.estadoTareaID === 3}
                        size={match_md ? "medium" : "small"}
                    >
                        {tarea.estadoTareaID === 1 ? <DoneIcon /> : <DoneAllIcon />}
                    </IconButton>
                </span>
            </Tooltip>
        </div>
    )
}