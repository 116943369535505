import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import { getServicios, deleteServicio, checkServicio, activarNotificacion } from "../../helpers/servicios";
import FullScreenDialog from "../fullScreenDialog";
import NuevoServicio from "./nuevoServicio";
import moment from "moment";
import FloatingActionButtons from "../fabButton";
import CustomTable, { StyledTableCell, StyledTableRow } from "../customTable";
import CustomDialog from "../customDialog";
import CustomSnackbar from '../customSnackbar';
import { GetPermisoUsuarioActual } from '../../helpers/empresa';


import { useAuth0 } from "../../react-auth0-spa";


export default function Servicios() {
    const { user, getTokenSilently } = useAuth0();
    const [openDialog, setOpenDialog] = React.useState(false);
    const initialCustomDialog = { show: false, title: "", text: "", buttons: [] };
    const [customDialog, setCustomDialog] = React.useState(initialCustomDialog);
    const [customSnackbar, setCustomSnackbar] = React.useState(undefined);
    const [servicios, setServicios] = useState([]);
    const [usuarioTareas, setUsuarioTareas] = useState(false);

    const initialState = {
        servicioID: 0,
        titulo: '',
        fechaServicio: moment().format("yyyy-MM-DDTHH:mm"),
        usuarioCreacion: '',
        usuarioFinalizacion: '',
        empresaID: 0,
        personaID: 0,
        NombreCompleto: '',
        importe: 0
    }
    
    const [servicioSeleccionado, setServicioSeleccionado] = useState(initialState)

    const handleClose = () => {
        setOpenDialog(false)
        setServicioSeleccionado(initialState)
    };


    useEffect(() => {
        obtenerServicios();

        BuscarPermisoUsuarioActual();
    }, [])

    const obtenerServicios = async () => {
        const token = await getTokenSilently();
        getServicios(user, token)
            .then(respuestaServicios => {
                setServicios(respuestaServicios);
            })
    };
    const BuscarPermisoUsuarioActual = async () => {
        const token = await getTokenSilently();
        GetPermisoUsuarioActual(user, token)
            .then(respuestaPermiso => {
                setUsuarioTareas(respuestaPermiso.permisoID === 3);
            })
    }


    const finalizarServicio = servicio => {
        setCustomDialog({
            show: true,
            title: "Finalizar",
            text: "¿Está seguro que desea finalizar este Servicio?",
            buttons: [
                <Button onClick={() => setCustomDialog(initialCustomDialog)}>Cancelar</Button>,
                <Button color="success" onClick={() => finalizar(servicio)}>Finalizar</Button>,
            ]
        });
    };

    const eliminarServicio = servicio => {
        setCustomDialog({
            show: true, 
            title: "Eliminar", 
            text: "¿Está seguro que desea eliminar este Servicio?", 
            buttons: [
                <Button onClick={() => setCustomDialog(initialCustomDialog)}>Cancelar</Button>,
                <Button color="error" onClick={() => eliminar(servicio)}>Aceptar</Button>,
            ]
        });
    };

    const finalizar = async (servicio) => {
        const token = await getTokenSilently();
        const result = await checkServicio(user, token, servicio);
        if (result.status === 'Success') {
            guardarServicio(servicio, "DELETE")
        }

        setCustomSnackbar({
            mensaje: result.message,
            tipo: result.status.toLowerCase(),
            direccion: "right",
            open: true,
            handleClose: () => setCustomSnackbar(prevState => ({...prevState, open: false}))
        });

        setCustomDialog(initialCustomDialog)
    }


    const eliminar = async (servicio) => {
        const token = await getTokenSilently();
        const result = await deleteServicio(servicio, user, token);
        if (result.status === 'Success') {
            guardarServicio(servicio, "DELETE")
        }

        setCustomSnackbar({
            mensaje: result.message,
            tipo: result.status.toLowerCase(),
            direccion: "right",
            open: true,
            handleClose: () => setCustomSnackbar(prevState => ({...prevState, open: false}))
        });

        setCustomDialog(initialCustomDialog)
    }


    const editarServicio = servicio => {
        setServicioSeleccionado(servicio);
        setOpenDialog(true);
    };

    const guardarServicio = (servicio, action) => {
        switch (action) {
            case "POST":
                setServicios([...servicios, servicio]); 
                break;
            case "PUT":
                setServicios([...servicios.map(s => s.servicioID === servicio.servicioID ? {...servicio} : s)]); 
                break;
            case "DELETE":
                setServicios([...servicios.filter(s => s.servicioID !== servicio.servicioID)]);
                break;
        }
        handleClose();
    }
    
    const handleNotifications = async (servicio, activar = true) => {
        const token = await getTokenSilently();
        const result = await activarNotificacion(servicio, activar, user, token);
        if(result) {
            setServicios([...servicios.map(s => s.servicioID === servicio.servicioID ? {...servicio, notificar: activar} : s)]);
        }
    }


    return (
        <div>
            <h1 className="titulosVistas">Servicios</h1>
            <hr />
            <CustomTable headers={["Fecha", "Título", ""]} rows={servicios}>
                {servicios.map((servicio) => (
                    <StyledTableRow sx={{position: "relative"}} key={servicio.servicioID}>
                        <StyledTableCell sx={{width: 0}}>{moment(servicio.fechaServicio).format("L")}</StyledTableCell>
                        <StyledTableCell sx={{zIndex:5}}>
                            <button className="buttonActionTable" onClick={() => editarServicio(servicio)} disabled={usuarioTareas}></button>
                            {servicio.titulo}
                        </StyledTableCell>
                        <StyledTableCell sx={{width:0, zIndex:10, px: 1, py: 1}}>
                            <div style={{ display: 'flex', gap: 5 }}>
                                {
                                    !usuarioTareas && 
                                    <>
                                        {servicio.notificar ? (
                                            <Tooltip title="Dejar de Notificar Cambios" arrow disableInteractive>
                                                <span>
                                                    <IconButton 
                                                        sx={(theme) => ({ bgcolor: theme.palette.primary.mainOpacity, color: theme.palette.primary.main })} 
                                                        onClick={() => handleNotifications(servicio, false)} 
                                                        aria-label="notify" 
                                                        disabled={usuarioTareas}
                                                    >
                                                        <NotificationsActiveIcon />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Notificar Cambios" arrow disableInteractive>
                                                <span>
                                                    <IconButton onClick={() => handleNotifications(servicio)} aria-label="notify" disabled={usuarioTareas}>
                                                        <NotificationsNoneIcon />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        )}
                                    </>
                                }
                                <Tooltip title="Eliminar" arrow disableInteractive>
                                    <span>
                                        <IconButton onClick={() => eliminarServicio(servicio)} aria-label="delete" disabled={usuarioTareas}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Finalizar" arrow disableInteractive>
                                    <span>
                                        <IconButton onClick={() => finalizarServicio(servicio)} aria-label="finish" disabled={usuarioTareas}>
                                            <CheckIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </div>
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
            </CustomTable>

            <FloatingActionButtons onClick={() => setOpenDialog(true)} color="primary" position="fixed" disabled={usuarioTareas}>
                <AddIcon />
            </FloatingActionButtons>

            <FullScreenDialog
                open={openDialog}
                handleClose={handleClose}
                titulo="Datos del Servicio"
                handleSave={() => console.log('asdas')}
            >
                <NuevoServicio servicioSeleccionado={servicioSeleccionado} guardarServicio={guardarServicio} />
            </FullScreenDialog>


            <CustomDialog {...customDialog} />
            <CustomSnackbar {...customSnackbar} ></CustomSnackbar>
        </div>
    )
}