import React, { useRef, useCallback, useEffect, useState } from "react"
//Icons
import AddIcon from '@mui/icons-material/Add';

//Custom Components
import FloatingActionButtons from "../fabButton";
import FullScreenDialog from "../fullScreenDialog";
import CustomDialog from "../customDialog";
import CustomSnackbar from '../customSnackbar';
import CustomTable, { StyledTableCell, StyledTableRow } from "../customTable";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { GetPermisoUsuarioActual } from '../../helpers/empresa';

//Views
import NuevaLocalidad from "./nuevaLocalidad";

//Helpers
import { getProvincias } from "../../helpers/provincias";
import { getLocalidades, deleteLocalidad } from "../../helpers/localidades";

import { useAuth0 } from "../../react-auth0-spa";
import { result } from "lodash";

export default function Localidades() {
    const { user, getTokenSilently } = useAuth0();
    const initialCustomDialog = { show: false, title: "", text: "", buttons: [] };
    const [customDialog, setCustomDialog] = useState(initialCustomDialog);
    const [customSnackbar, setCustomSnackbar] = React.useState(undefined);
    const [usuarioTareas, setUsuarioTareas] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [localidades, setLocalidades] = useState([]);
    const provincias = useRef([]);

    const initialState = {
        localidadID: 0,
        nombre: '',
        codigoPostal: '',
        provinciaID: 0
    }
    const [localidadSeleccionada, setLocalidadSeleccionada] = useState(initialState)

    useEffect(() => {
        obtenerLocalidades();
        BuscarPermisoUsuarioActual();
        InitialFetch();
        return function() {
            provincias.current = [];
        }
    }, [])

     const obtenerLocalidades = async () => {
        const token = await getTokenSilently();
        getLocalidades(user, token)
            .then(respuestaLocalidades => {
                setLocalidades(respuestaLocalidades);
            })
    };

    const InitialFetch = async () => {
        try {
            const token = await getTokenSilently();
            const provinciasResponse = await getProvincias(user, token);
            provincias.current = provinciasResponse;
        } catch(error) {
            console.error('Ocurrio un error en Localidad - InitialFetch: \n', error);
        }
    }

    const handleOpenDialog = useCallback(() => setOpenDialog(true), []);

    const handleClose = useCallback(() => {
        setOpenDialog(false)
        setLocalidadSeleccionada(initialState)
    }, [initialState]);

    const BuscarPermisoUsuarioActual = async () => {
        const token = await getTokenSilently();
        GetPermisoUsuarioActual(user, token)
            .then(respuestaPermiso => {
                setUsuarioTareas(respuestaPermiso.permisoID === 3);
            })
    }

    const eliminarLocalidad = localidad => {
        setCustomDialog({
            show: true, 
            title: "Eliminar", 
            text: "¿Está seguro que desea eliminar esta Localidad?", 
            buttons: [
                <Button onClick={() => setCustomDialog(initialCustomDialog)}>Cancelar</Button>,
                <Button color="error" onClick={() => eliminar(localidad)}>Aceptar</Button>,
            ]
        });
    };

    const eliminar = async (localidad) => {
        const token = await getTokenSilently();
        const result = await deleteLocalidad(localidad, user, token);
        if (result.status === 'Success') {
            guardarLocalidad(localidad, "DELETE")
        }

        setCustomSnackbar({
            mensaje: result.message,
            tipo: result.status.toLowerCase(),
            direccion: "right",
            open: true,
            handleClose: () => setCustomSnackbar(prevState => ({...prevState, open: false}))
        });

        setCustomDialog(initialCustomDialog)
    }

     const editarLocalidad = localidad => {
        setLocalidadSeleccionada(localidad);
        setOpenDialog(true);
    };

    const guardarLocalidad = useCallback((localidad, action) => {
        switch (action) {
            case "POST":
                setLocalidades([...localidades, localidad]);
                break;
            case "PUT":
                setLocalidades([...localidades.map(l => l.localidadID === localidad.localidadID ? { ...localidad } : l)]);
                break;
            case "DELETE":
                setLocalidades([...localidades.filter(l => l.localidadID !== localidad.localidadID)]);
                setCustomDialog(initialCustomDialog)
                break;
        }

        handleClose();

    }, [initialCustomDialog])
    return (
        <div>
            <h1 className="titulosVistas">Listado de Localidades</h1>
            <hr />
            
             <CustomTable headers={["Nombre", "Código postal", "Provincia", ""]} rows={localidades}>
                {localidades.map((localidad) => (
                    <StyledTableRow sx={{position: "relative"}} key={localidad.localidadID}>
                        <StyledTableCell sx={{}}>
                        <button className="buttonActionTable" onClick={() => editarLocalidad(localidad)} disabled={usuarioTareas}></button>{localidad.nombre}
                        </StyledTableCell>
                        <StyledTableCell>
                        {localidad.codigoPostal}
                        </StyledTableCell>
                        <StyledTableCell >
                           {localidad.provincia.provinciaNombre}
                        </StyledTableCell>
                        <StyledTableCell sx={{width: 0, zIndex:10, px: 1, py: 1}}>
                            <Tooltip title="Eliminar" arrow disableInteractive>
                                <span>
                                    <IconButton onClick={() => eliminarLocalidad(localidad)} aria-label="delete" disabled={usuarioTareas}>
                                        <DeleteIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
            </CustomTable>

            <FloatingActionButtons onClick={handleOpenDialog} color="primary" position="fixed" disabled={usuarioTareas}>
                <AddIcon />
            </FloatingActionButtons>

            <CustomDialog {...customDialog} />

            <FullScreenDialog
                open={openDialog}
                handleClose={handleClose}
                titulo="Datos de la Localidad"
            >
                <NuevaLocalidad 
                    localidadSeleccionada={localidadSeleccionada} 
                    guardar={guardarLocalidad}
                    provincias={provincias.current}
                    setCustomSnackbar={setCustomSnackbar}
                />
            </FullScreenDialog>
            <CustomSnackbar {...customSnackbar} ></CustomSnackbar>
        </div>
    )
}
