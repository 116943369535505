import moment from 'moment'

export const getTareasEstado = async(user, estadoTareaID = 1, fechaDesde, fechaHasta, servicioID = 0, tipoTareaID = 0, usuarioID, sinFecha, token) => {
    const fechaD = moment(fechaDesde).format();
    const fechaH = moment(fechaHasta).format();
    
    const response = await fetch(`api/Tareas/GetTareasEstado/${user.sub}?EstadoTareaID=${estadoTareaID}&FechaDesde=${fechaD}&FechaHasta=${fechaH}&ServicioID=${servicioID}&TipoTareaID=${tipoTareaID}&UsuarioID=${usuarioID}&SinFecha=${sinFecha}`, {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    const data = await response.json();
    return data;
}

export const getTareasEstadoAgenda = async(user, estadoTareaID = 1, fechaDesde, fechaHasta, servicioID = 0, tipoTareaID = 0, token) => {
    const fechaD = moment(fechaDesde).format();
    const fechaH = moment(fechaHasta).format();
    
    const response = await fetch(`api/Tareas/GetTareasEstadoAgenda/${user.sub}?EstadoTareaID=${estadoTareaID}&FechaDesde=${fechaD}&FechaHasta=${fechaH}&ServicioID=${servicioID}&TipoTareaID=${tipoTareaID}`, {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    const data = await response.json();
    return data;
}


export const postTarea = async(tarea, user, token) => {
    tarea.nombre = tarea.nombre.toUpperCase();

    const response = await fetch('api/Tareas/' + user.sub, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        body: JSON.stringify(tarea)
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    const data = await response.json();
    return data;
}

export const putTarea = async(tarea, user, token) => {
    tarea.nombre = tarea.nombre.toUpperCase();
    if(moment(tarea.fechaTarea).isAfter(tarea.fechaTareaFin)) {
        tarea.fechaTareaFin = tarea.fechaTarea;
    }
    
    const response = await fetch('api/Tareas/' + tarea.tareaID + '/' + user.sub, {
        method: "PUT",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        body: JSON.stringify(tarea)
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    const data = await response.json();
    return data;
}

export const deleteTarea = async(tarea, user, token) => {
    const response = await fetch('api/Tareas/' + tarea.tareaID + '/' + user.sub, {
        method: "DELETE",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    return response.ok;
}

export const enProcesoTarea = async(tarea, user, token) => {
    const response = await fetch('api/Tareas/EnProceso/' + tarea.tareaID + '/' + user.sub, {
        method: "PUT",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    //return response.ok;
    const data = await response.json();
    return data;
}

export const checkTarea = async(tarea, user, token) => {
    const response = await fetch('api/Tareas/CheckTarea/' + tarea.tareaID + '/' + user.sub, {
        method: "PUT",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    // return response.ok;
    const data = await response.json();
    return data;
}

export const activarNotificacion = async(tarea, user, token, activar = true) => {
    const response = await fetch(`api/Tareas/ActivarNotificaciones/${tarea.tareaID}/${user.sub}?activar=${Number(activar)}`, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    const data = await response.json();
    return data;
}

export const getTareasObservaciones = async(user, tareaFiltrar, token) => {
    const fechaDesde = moment(tareaFiltrar.fechaDesde).format();
    const fechaHasta = moment(tareaFiltrar.fechaHasta).format();

    const response = await fetch(`api/Tareas/GetTareasObservaciones/${user.sub}?EstadoTareaID=${tareaFiltrar.estadoTareaID}&FechaDesde=${fechaDesde}&FechaHasta=${fechaHasta}`, {
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
    });

    // if (response.status === 401) {
    //     await authService.signIn();
    // }

    const data = await response.json();
    return data;
}