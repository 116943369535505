import {createTheme} from '@mui/material/styles';

const taskColors = {
  inProcessTask: {
    main: "#ffa13d",
    light: '#ffd26d',
    dark: '#c77200',
    contrastText: '#ffffff'
  },
  finishedTask: {
    main: "#12994b",
    light: '#55cb78',
    dark: '#006a20',
    contrastText: '#ffffff'
  }
}

const pendingTaskColor = {
  dark: {
    pendingTask: {
      main: "#EC3D3D",
      light: '#BF2A2A',
      dark: '#BF2A2A',
      contrastText: '#fff',
      hoverOpacity: 0.1,
    },
  },
  light: {
    pendingTask: {
      main: "#EC3D3D",
      light: '#BF2A2A',
      dark: '#BF2A2A',
      contrastText: '#fff'
    }
  }
}

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#0076be',
      light: '#209ce8',
      dark: '#075585',
      mainOpacity: '#0076be24'
    },
    success: {
      main: '#00BE48',
      contrastText: '#fff'
    },
    info: {
      main: '#00BEA7',
    },
    danger: {
      main: '#f87d7d'
    },
    grey: {
      agrupadosTabla: '#000',
    },
    action: {
      hoverOpacity: 0.1,
    },
    ...taskColors,
    ...pendingTaskColor.dark,
  },
});

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#0076be',
      light: '#209ce8',
      dark: '#075585',
      mainOpacity: '#0076be24'
    },
    success: {
      main: '#00BE48',
      light: '#20e36a',
      dark: '#098237',
      contrastText: '#fff'
    },
    info: {
      main: '#0076be',
    },
    danger: {
      main: '#f87d7d'
    },
    grey: {
      agrupadosTabla: '#e6e6e6',
    },
    action: {
      hoverOpacity: 0.1,
    },
    ...taskColors,
    ...pendingTaskColor.light,
  },
});