import React from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';

export function Layout({ toggleTheme, showMenu = true, ...props }) {
  return (
    <div>
      {showMenu && <NavMenu handleTheme={toggleTheme} />}
      <Container>
        {props.children}
      </Container>
    </div>
  );
}
