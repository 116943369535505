import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';

import LogoLight from '../../images/logo-mis-tareas-light.png';
import LogoDark from '../../images/logo-mis-tareas-dark.png';
import LogoLightWebp from '../../images/logo-mis-tareas-light.webp';
import LogoDarkWebp from '../../images/logo-mis-tareas-dark.webp';

import { imagenEmpresa } from '../../helpers/empresa'


import { useAuth0 } from "../../react-auth0-spa";


import './index.css'

export default function Home() {
    const { user, getTokenSilently } = useAuth0();
    const theme = useTheme();
    const [imagen, setImage] = useState('');


    useEffect(() => {
        async function GetImagen() {
            const token = await getTokenSilently();
            const img = await imagenEmpresa(user, token);
            if(img) {
                setImage(img);
            }
        }

        GetImagen();
    }, []);

    return (
        <div className="container-img">
            {
                imagen ? (
                    <picture>
                        <img className="img" src={imagen} alt="Mis tareas logo" />
                    </picture>
                ) : (
                    <picture>
                        <source srcSet={theme.palette.mode === 'dark' ? LogoDarkWebp : LogoLightWebp} type="image/webp" />
                        <img className="img" src={theme.palette.mode === 'dark' ? LogoDark : LogoLight} alt="Mis tareas logo" />
                    </picture>
                )
            }
        </div>
    )
}