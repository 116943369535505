import React from "react"
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import { getTipoTareas, deleteTipoTarea } from "../../helpers/tipoTareas";
import FullScreenDialog from "../fullScreenDialog";
import FloatingActionButtons from "../fabButton";
import CustomTable, { StyledTableCell, StyledTableRow } from "../customTable";
import NuevoTipoTarea from "./nuevoTipoTarea";
import CustomDialog from "../customDialog";
import CustomSnackbar from '../customSnackbar';
import { GetPermisoUsuarioActual } from "../../helpers/empresa";

import { useAuth0 } from "../../react-auth0-spa";


export default function TipoTareas() {
    const { user, getTokenSilently } = useAuth0();
    const [openDialog, setOpenDialog] = React.useState(false);
    const initialCustomDialog = { show: false, title: "", text: "", buttons: [] };
    const [customDialog, setCustomDialog] = React.useState(initialCustomDialog);
    const [customSnackbar, setCustomSnackbar] = React.useState(undefined);
    const [tipoTareas, setTipoTareas] = useState([]);
    const [usuarioTareas, setUsuarioTareas] = useState(false);

    const initialState = {
        tipoTareaID: 0,
        tipoTareaNombre: ''
    }

    const [tipoTareasSeleccionada, setTipoTareasSeleccionada] = useState(initialState)

    const handleClose = () => {
        setOpenDialog(false)
        setTipoTareasSeleccionada(initialState)
    };


    useEffect(() => {
        obtenerTareas();

        BuscarPermisoUsuarioActual();
    }, [])

    const obtenerTareas = async () => {
        const token = await getTokenSilently();
        getTipoTareas(user, token)
            .then(respuestaTipoTareas => {
                setTipoTareas(respuestaTipoTareas);
            })
    };
    const BuscarPermisoUsuarioActual = async () => {
        const token = await getTokenSilently();
        GetPermisoUsuarioActual(user, token)
            .then(respuestaPermiso => {
                setUsuarioTareas(respuestaPermiso.permisoID === 3);
            })
    }

    const eliminarTipoTarea = tipoTarea => {
        setCustomDialog({
            show: true, 
            title: "Eliminar", 
            text: "¿Está seguro que desea eliminar este Tipo de Tarea?", 
            buttons: [
                <Button onClick={() => setCustomDialog(initialCustomDialog)}>Cancelar</Button>,
                <Button color="error" onClick={() => eliminar(tipoTarea)}>Aceptar</Button>,
            ]
        });
    };

    const eliminar = async (tipoTarea) => {
        const token = await getTokenSilently();
        const result = await deleteTipoTarea(tipoTarea, user, token);
        if (result.status === 'Success') {
            guardarTipoTarea(tipoTarea, "DELETE")
        }

        setCustomSnackbar({
            mensaje: result.message,
            tipo: result.status.toLowerCase(),
            direccion: "right",
            open: true,
            handleClose: () => setCustomSnackbar(prevState => ({...prevState, open: false}))
        });

        setCustomDialog(initialCustomDialog)
    }

    const editarTipoTarea = tipoTarea => {
        setTipoTareasSeleccionada(tipoTarea);
        setOpenDialog(true);
    };

    const guardarTipoTarea = (tipoTarea, action) => {
        switch (action) {
            case "POST":
                setTipoTareas([...tipoTareas, tipoTarea]); 
                break;
            case "PUT":
                setTipoTareas([...tipoTareas.map(tt => tt.tipoTareaID === tipoTarea.tipoTareaID ? {...tipoTarea} : tt)]); 
                break;
            case "DELETE":
                setTipoTareas([...tipoTareas.filter(tt => tt.tipoTareaID !== tipoTarea.tipoTareaID)]);
                break;
        }
        handleClose();
    }

    return (
        <div>
            <h1 className="titulosVistas">Tipos de Tareas</h1>
            <hr />
            <CustomTable headers={["Nombre", ""]} rows={tipoTareas}>
                {tipoTareas.map((tipoTarea) => (
                    <StyledTableRow sx={{position: "relative"}} key={tipoTarea.tipoTareaID}>
                        <StyledTableCell sx={{zIndex:5}}>
                            <button className="buttonActionTable" onClick={() => editarTipoTarea(tipoTarea)} disabled={usuarioTareas}></button>{tipoTarea.tipoTareaNombre}
                        </StyledTableCell>
                        <StyledTableCell sx={{width: 0, zIndex:10, px: 1, py: 1}}>
                            <Tooltip title="Eliminar" arrow disableInteractive>
                                <span>
                                    <IconButton onClick={() => eliminarTipoTarea(tipoTarea)} aria-label="delete" disabled={usuarioTareas}>
                                        <DeleteIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
            </CustomTable>

            <FloatingActionButtons onClick={() => setOpenDialog(true)} color="primary" position="fixed" disabled={usuarioTareas}>
                <AddIcon />
            </FloatingActionButtons>

            <FullScreenDialog
                open={openDialog}
                handleClose={handleClose}
                titulo="Datos del Tipo de Tarea"
            >
                <NuevoTipoTarea tipoTareasSeleccionada={tipoTareasSeleccionada} guardarTipoTarea={guardarTipoTarea} />
            </FullScreenDialog>


            <CustomDialog {...customDialog} />
            <CustomSnackbar {...customSnackbar} ></CustomSnackbar>
        </div>
    )
}